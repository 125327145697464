export const REAL_TIME_COVERAGE_PRODUCT_TYPE = 'Product';
export const REAL_TIME_COVERAGE_REGION_TYPE = 'Region';
export const REAL_TIME_COVERAGE_HOME_TYPE = 'Home';

export const REAL_TIME_COVERAGE_TYPES = {
    PRODUCT: REAL_TIME_COVERAGE_PRODUCT_TYPE,
    REGION: REAL_TIME_COVERAGE_REGION_TYPE,
    HOME: REAL_TIME_COVERAGE_HOME_TYPE
};

export const REAL_TIME_COVERAGE_DOC_TYPE_DEFAULT = 'Preview/Comment';
export const REAL_TIME_COVERAGE_DOC_TYPE_COMMENTARY = 'Commentary';
export const REAL_TIME_COVERAGE_DOC_TYPE_PREVIEW = 'Preview';
export const REAL_TIME_COVERAGE_DOC_TYPE_BLURB = 'Blurb';


export const REAL_TIME_COVERAGE_DOC_TYPE_DROPDOWN = [
    {
        label: REAL_TIME_COVERAGE_DOC_TYPE_DEFAULT,
        value: ''
    },
    {
        label: REAL_TIME_COVERAGE_DOC_TYPE_COMMENTARY,
        value: REAL_TIME_COVERAGE_DOC_TYPE_COMMENTARY
    },
    {
        label: REAL_TIME_COVERAGE_DOC_TYPE_PREVIEW,
        value: REAL_TIME_COVERAGE_DOC_TYPE_PREVIEW
    }
];

export const REAL_TIME_COVERAGE_REGION_DEFAULT = 'Country/Region';

export const REAL_TIME_COVERAGE_REGION_ALL = 'ALL';

export const REAL_TIME_COVERAGE_REGION_DEVELOPED_MARKETS = 'Developed Markets';

export const REAL_TIME_COVERAGE_REGION_EMERGING_MARKETS = 'Emerging Markets';

export const REAL_TIME_COVERAGE_REGION_G10_LABEL = 'G10';

export const REAL_TIME_COVERAGE_REGION_ALL_LABEL = 'All';

export const REAL_TIME_COVERAGE_REGION_DEVELOPED_MARKETS_VALUE = 'ALL_DEVELOPED_MARKETS';

export const REAL_TIME_COVERAGE_REGION_ASIA_PACIFIC = 'Asia-Pacific';
export const REAL_TIME_COVERAGE_REGION_AUSTRALIA = 'Australia';
export const REAL_TIME_COVERAGE_REGION_JAPAN = 'Japan';
export const REAL_TIME_COVERAGE_REGION_NEW_ZEALAND = 'New Zealand';

export const REAL_TIME_COVERAGE_REGION_NORTH_AMERICA = 'North America';
export const REAL_TIME_COVERAGE_REGION_CANADA = 'Canada';
export const REAL_TIME_COVERAGE_REGION_US = 'US';

export const REAL_TIME_COVERAGE_REGION_EUROPE = 'Europe';
export const REAL_TIME_COVERAGE_REGION_EUROZONE = 'Eurozone';
export const REAL_TIME_COVERAGE_REGION_NORWAY = 'Norway';
export const REAL_TIME_COVERAGE_REGION_SWEDEN = 'Sweden';
export const REAL_TIME_COVERAGE_REGION_SWITZERLAND = 'Switzerland';
export const REAL_TIME_COVERAGE_REGION_UK = 'UK';

export const REAL_TIME_COVERAGE_REGION_EMEA_LABEL = 'Emerging Markets';
export const REAL_TIME_COVERAGE_REGION_EMEA_VALUE = 'Global EM';

export const REAL_TIME_COVERAGE_REGION_EMEA2 = 'EMEA';
export const REAL_TIME_COVERAGE_REGION_HUNGARY = 'Hungary';
export const REAL_TIME_COVERAGE_REGION_POLAND = 'Poland';
export const REAL_TIME_COVERAGE_REGION_RUSSIA = 'Russia';
export const REAL_TIME_COVERAGE_REGION_SOUTH_AFRICA = 'South Africa';
export const REAL_TIME_COVERAGE_REGION_TURKEY = 'Turkey';

export const REAL_TIME_COVERAGE_REGION_ASIA = 'Asia';
export const REAL_TIME_COVERAGE_REGION_CHINA = 'China';
export const REAL_TIME_COVERAGE_REGION_HONG_KONG = 'Hong Kong';
export const REAL_TIME_COVERAGE_REGION_INDIA = 'India';
export const REAL_TIME_COVERAGE_REGION_INDONESIA = 'Indonesia';
export const REAL_TIME_COVERAGE_REGION_MALAYSIA = 'Malaysia';
export const REAL_TIME_COVERAGE_REGION_PHILIPPINES = 'Philippines';
export const REAL_TIME_COVERAGE_REGION_SINGAPORE = 'Singapore';
export const REAL_TIME_COVERAGE_REGION_SOUTH_KOREA = 'South Korea';
export const REAL_TIME_COVERAGE_REGION_TAIWAN = 'Taiwan';
export const REAL_TIME_COVERAGE_REGION_THAILAND = 'Thailand';

export const REAL_TIME_COVERAGE_REGION_LATAM_LABEL = 'Latam';
export const REAL_TIME_COVERAGE_REGION_LATAM_VALUE = 'Latam';
export const REAL_TIME_COVERAGE_REGION_BRAZIL = 'Brazil';
export const REAL_TIME_COVERAGE_REGION_MEXICO = 'Mexico';

export const REAL_TIME_COVERAGE_REGION_COMMODITIES = 'Commodities';
export const REAL_TIME_COVERAGE_REGION_ENERGY = 'Energy';
export const REAL_TIME_COVERAGE_REGION_BASE_METALS = 'Base Metals';
export const REAL_TIME_COVERAGE_REGION_PRECIOUS_METALS = 'Precious Metals';

export const REAL_TIME_COVERAGE_REGION_ALL_FX_VALUE = "ALL_FX";
export const REAL_TIME_COVERAGE_REGION_ALL_FX_G10_VALUE = "ALL_FX_G10";
export const REAL_TIME_COVERAGE_REGION_ALL_FX_EMERGING_MARKETS_VALUE = "ALL_FX_EMERGING_MARKETS";
export const REAL_TIME_COVERAGE_REGION_ALL_RATES_VALUE = "ALL_RATES";
export const REAL_TIME_COVERAGE_REGION_ALL_EMERGING_MARKETS_VALUE = "ALL_EMERGING_MARKETS";
export const REAL_TIME_COVERAGE_REGION_ALL_EMERGING_MARKETS_LATAM_VALUE = "ALL_EMERGING_MARKETS_LATAM";
export const REAL_TIME_COVERAGE_REGION_ALL_COMMODITIES_VALUE = "ALL_COMMODITIES";
export const REAL_TIME_COVERAGE_REGION_ALL_MACRO_VALUE = "ALL_MACRO";

export const REAL_TIME_COVERAGE_PAGE_SIZE = 10;

export const REAL_TIME_COVERAGE_INTIAL_STATE = {
    loading: true,
    data: null,
    error: null,
    currentPage: 1,
    docType: "",
    regionName: ""
};

export const REAL_TIME_COVERAGE_RELOAD_STATE = {
    loading: true,
    data: null,
    error: null
};

export const REAL_TIME_COVERAGE_KEYPOINTS_CHAR_LIMIT = 250;


const PADDING_FIRST_LEVEL = "0px 0px 0px 10px";
const PADDING_SECOND_LEVEL = "0px 0px 0px 20px";
const PADDING_THIRD_LEVEL = "0px 0px 0px 30px";
const PADDING_FOURTH_LEVEL = "0px 0px 0px 40px";

export const REAL_TIME_COVERAGE_REGION_OPTIONS = [
    {
        label: REAL_TIME_COVERAGE_REGION_ALL,
        value: '',
        padding: PADDING_FIRST_LEVEL,
        fontWeight: 'bold'
    },
    {
        label: REAL_TIME_COVERAGE_REGION_DEVELOPED_MARKETS,
        value: REAL_TIME_COVERAGE_REGION_DEVELOPED_MARKETS_VALUE,
        padding: PADDING_SECOND_LEVEL,
        fontWeight: 'bold'
    },
    {
        label: REAL_TIME_COVERAGE_REGION_ASIA_PACIFIC,
        value: REAL_TIME_COVERAGE_REGION_ASIA_PACIFIC,
        padding: PADDING_THIRD_LEVEL,
        fontWeight: 'bold'
    },
    {
        label: REAL_TIME_COVERAGE_REGION_AUSTRALIA,
        value: REAL_TIME_COVERAGE_REGION_AUSTRALIA,
        padding: PADDING_FOURTH_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_JAPAN,
        value: REAL_TIME_COVERAGE_REGION_JAPAN,
        padding: PADDING_FOURTH_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_NEW_ZEALAND,
        value: REAL_TIME_COVERAGE_REGION_NEW_ZEALAND,
        padding: PADDING_FOURTH_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_NORTH_AMERICA,
        value: REAL_TIME_COVERAGE_REGION_NORTH_AMERICA,
        padding: PADDING_THIRD_LEVEL,
        fontWeight: 'bold'
    },
    {
        label: REAL_TIME_COVERAGE_REGION_CANADA,
        value: REAL_TIME_COVERAGE_REGION_CANADA,
        padding: PADDING_FOURTH_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_US,
        value: REAL_TIME_COVERAGE_REGION_US,
        padding: PADDING_FOURTH_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_EUROPE,
        value: REAL_TIME_COVERAGE_REGION_EUROPE,
        padding: PADDING_THIRD_LEVEL,
        fontWeight: 'bold'
    },
    {
        label: REAL_TIME_COVERAGE_REGION_EUROZONE,
        value: REAL_TIME_COVERAGE_REGION_EUROZONE,
        padding: PADDING_FOURTH_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_NORWAY,
        value: REAL_TIME_COVERAGE_REGION_NORWAY,
        padding: PADDING_FOURTH_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_SWEDEN,
        value: REAL_TIME_COVERAGE_REGION_SWEDEN,
        padding: PADDING_FOURTH_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_SWITZERLAND,
        value: REAL_TIME_COVERAGE_REGION_SWITZERLAND,
        padding: PADDING_FOURTH_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_UK,
        value: REAL_TIME_COVERAGE_REGION_UK,
        padding: PADDING_FOURTH_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_EMEA_LABEL,
        value: REAL_TIME_COVERAGE_REGION_EMEA_VALUE,
        padding: PADDING_SECOND_LEVEL,
        fontWeight: 'bold'
    },
    {
        label: REAL_TIME_COVERAGE_REGION_EMEA2,
        value: REAL_TIME_COVERAGE_REGION_EMEA2,
        padding: PADDING_THIRD_LEVEL,
        fontWeight: 'bold'
    },
    {
        label: REAL_TIME_COVERAGE_REGION_HUNGARY,
        value: REAL_TIME_COVERAGE_REGION_HUNGARY,
        padding: PADDING_FOURTH_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_POLAND,
        value: REAL_TIME_COVERAGE_REGION_POLAND,
        padding: PADDING_FOURTH_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_RUSSIA,
        value: REAL_TIME_COVERAGE_REGION_RUSSIA,
        padding: PADDING_FOURTH_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_SOUTH_AFRICA,
        value: REAL_TIME_COVERAGE_REGION_SOUTH_AFRICA,
        padding: PADDING_FOURTH_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_TURKEY,
        value: REAL_TIME_COVERAGE_REGION_TURKEY,
        padding: PADDING_FOURTH_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_ASIA,
        value: REAL_TIME_COVERAGE_REGION_ASIA,
        padding: PADDING_THIRD_LEVEL,
        fontWeight: 'bold'
    },
    {
        label: REAL_TIME_COVERAGE_REGION_CHINA,
        value: REAL_TIME_COVERAGE_REGION_CHINA,
        padding: PADDING_FOURTH_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_HONG_KONG,
        value: REAL_TIME_COVERAGE_REGION_HONG_KONG,
        padding: PADDING_FOURTH_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_INDIA,
        value: REAL_TIME_COVERAGE_REGION_INDIA,
        padding: PADDING_FOURTH_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_INDONESIA,
        value: REAL_TIME_COVERAGE_REGION_INDONESIA,
        padding: PADDING_FOURTH_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_MALAYSIA,
        value: REAL_TIME_COVERAGE_REGION_MALAYSIA,
        padding: PADDING_FOURTH_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_PHILIPPINES,
        value: REAL_TIME_COVERAGE_REGION_PHILIPPINES,
        padding: PADDING_FOURTH_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_SINGAPORE,
        value: REAL_TIME_COVERAGE_REGION_SINGAPORE,
        padding: PADDING_FOURTH_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_SOUTH_KOREA,
        value: REAL_TIME_COVERAGE_REGION_SOUTH_KOREA,
        padding: PADDING_FOURTH_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_TAIWAN,
        value: REAL_TIME_COVERAGE_REGION_TAIWAN,
        padding: PADDING_FOURTH_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_THAILAND,
        value: REAL_TIME_COVERAGE_REGION_THAILAND,
        padding: PADDING_FOURTH_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_LATAM_LABEL,
        value: REAL_TIME_COVERAGE_REGION_LATAM_VALUE,
        padding: PADDING_THIRD_LEVEL,
        fontWeight: 'bold'
    },
    {
        label: REAL_TIME_COVERAGE_REGION_BRAZIL,
        value: REAL_TIME_COVERAGE_REGION_BRAZIL,
        padding: PADDING_FOURTH_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_MEXICO,
        value: REAL_TIME_COVERAGE_REGION_MEXICO,
        padding: PADDING_FOURTH_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_COMMODITIES,
        value: REAL_TIME_COVERAGE_REGION_COMMODITIES,
        padding: PADDING_THIRD_LEVEL,
        fontWeight: 'bold'
    },
    {
        label: REAL_TIME_COVERAGE_REGION_ENERGY,
        value: REAL_TIME_COVERAGE_REGION_ENERGY,
        padding: PADDING_FOURTH_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_PRECIOUS_METALS,
        value: REAL_TIME_COVERAGE_REGION_PRECIOUS_METALS,
        padding: PADDING_FOURTH_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_BASE_METALS,
        value: REAL_TIME_COVERAGE_REGION_BASE_METALS,
        padding: PADDING_FOURTH_LEVEL
    }
];

export const REAL_TIME_COVERAGE_REGION_OPTIONS_MACRO = [
    {
        label: REAL_TIME_COVERAGE_REGION_ALL_LABEL,
        value: REAL_TIME_COVERAGE_REGION_ALL_MACRO_VALUE,
        padding: PADDING_FIRST_LEVEL,
        fontWeight: 'bold'
    },
    {
        label: REAL_TIME_COVERAGE_REGION_US,
        value: REAL_TIME_COVERAGE_REGION_US,
        padding: PADDING_THIRD_LEVEL,
    },
    {
        label: REAL_TIME_COVERAGE_REGION_CANADA,
        value: REAL_TIME_COVERAGE_REGION_CANADA,
        padding: PADDING_THIRD_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_EUROPE,
        value: REAL_TIME_COVERAGE_REGION_EUROPE,
        padding: PADDING_THIRD_LEVEL,
    },
    {
        label: REAL_TIME_COVERAGE_REGION_ASIA_PACIFIC,
        value: REAL_TIME_COVERAGE_REGION_ASIA_PACIFIC,
        padding: PADDING_THIRD_LEVEL,
    }
];

export const REAL_TIME_COVERAGE_REGION_OPTIONS_RATES = [
    {
        label: REAL_TIME_COVERAGE_REGION_ALL_LABEL,
        value: REAL_TIME_COVERAGE_REGION_ALL_RATES_VALUE,
        padding: PADDING_FIRST_LEVEL,
        fontWeight: 'bold'
    },
    {
        label: REAL_TIME_COVERAGE_REGION_US,
        value: REAL_TIME_COVERAGE_REGION_US,
        padding: PADDING_THIRD_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_CANADA,
        value: REAL_TIME_COVERAGE_REGION_CANADA,
        padding: PADDING_THIRD_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_EUROPE,
        value: REAL_TIME_COVERAGE_REGION_EUROPE,
        padding: PADDING_THIRD_LEVEL,
    },
    {
        label: REAL_TIME_COVERAGE_REGION_ASIA_PACIFIC,
        value: REAL_TIME_COVERAGE_REGION_ASIA_PACIFIC,
        padding: PADDING_THIRD_LEVEL,
    }
];

export const REAL_TIME_COVERAGE_REGION_OPTIONS_EM = [
    {
        label: REAL_TIME_COVERAGE_REGION_ALL_LABEL,
        value: REAL_TIME_COVERAGE_REGION_ALL_EMERGING_MARKETS_VALUE,
        padding: PADDING_FIRST_LEVEL,
        fontWeight: 'bold'
    },
    {
        label: REAL_TIME_COVERAGE_REGION_ASIA,
        value: REAL_TIME_COVERAGE_REGION_ASIA,
        padding: PADDING_SECOND_LEVEL,
        fontWeight: 'bold'
    },
    {
        label: REAL_TIME_COVERAGE_REGION_CHINA,
        value: REAL_TIME_COVERAGE_REGION_CHINA,
        padding: PADDING_THIRD_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_HONG_KONG,
        value: REAL_TIME_COVERAGE_REGION_HONG_KONG,
        padding: PADDING_THIRD_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_INDIA,
        value: REAL_TIME_COVERAGE_REGION_INDIA,
        padding: PADDING_THIRD_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_INDONESIA,
        value: REAL_TIME_COVERAGE_REGION_INDONESIA,
        padding: PADDING_THIRD_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_MALAYSIA,
        value: REAL_TIME_COVERAGE_REGION_MALAYSIA,
        padding: PADDING_THIRD_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_PHILIPPINES,
        value: REAL_TIME_COVERAGE_REGION_PHILIPPINES,
        padding: PADDING_THIRD_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_SINGAPORE,
        value: REAL_TIME_COVERAGE_REGION_SINGAPORE,
        padding: PADDING_THIRD_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_SOUTH_KOREA,
        value: REAL_TIME_COVERAGE_REGION_SOUTH_KOREA,
        padding: PADDING_THIRD_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_TAIWAN,
        value: REAL_TIME_COVERAGE_REGION_TAIWAN,
        padding: PADDING_THIRD_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_THAILAND,
        value: REAL_TIME_COVERAGE_REGION_THAILAND,
        padding: PADDING_THIRD_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_EMEA2,
        value: REAL_TIME_COVERAGE_REGION_EMEA2,
        padding: PADDING_FIRST_LEVEL,
        fontWeight: 'bold'
    },
    {
        label: REAL_TIME_COVERAGE_REGION_HUNGARY,
        value: REAL_TIME_COVERAGE_REGION_HUNGARY,
        padding: PADDING_THIRD_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_POLAND,
        value: REAL_TIME_COVERAGE_REGION_POLAND,
        padding: PADDING_THIRD_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_RUSSIA,
        value: REAL_TIME_COVERAGE_REGION_RUSSIA,
        padding: PADDING_THIRD_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_SOUTH_AFRICA,
        value: REAL_TIME_COVERAGE_REGION_SOUTH_AFRICA,
        padding: PADDING_THIRD_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_TURKEY,
        value: REAL_TIME_COVERAGE_REGION_TURKEY,
        padding: PADDING_THIRD_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_LATAM_LABEL,
        value: REAL_TIME_COVERAGE_REGION_ALL_EMERGING_MARKETS_LATAM_VALUE,
        padding: PADDING_SECOND_LEVEL,
        fontWeight: 'bold'
    },
    {
        label: REAL_TIME_COVERAGE_REGION_BRAZIL,
        value: REAL_TIME_COVERAGE_REGION_BRAZIL,
        padding: PADDING_THIRD_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_MEXICO,
        value: REAL_TIME_COVERAGE_REGION_MEXICO,
        padding: PADDING_THIRD_LEVEL
    }
];

export const REAL_TIME_COVERAGE_REGION_OPTIONS_FX = [
    {
        label: REAL_TIME_COVERAGE_REGION_ALL_LABEL,
        value: REAL_TIME_COVERAGE_REGION_ALL_FX_VALUE,
        padding: PADDING_FIRST_LEVEL,
        fontWeight: 'bold'
    },
    {
        label: REAL_TIME_COVERAGE_REGION_G10_LABEL,
        value: REAL_TIME_COVERAGE_REGION_ALL_FX_G10_VALUE,
        padding: PADDING_SECOND_LEVEL,
        fontWeight: 'bold'
    },
    {
        label: REAL_TIME_COVERAGE_REGION_US,
        value: REAL_TIME_COVERAGE_REGION_US,
        padding: PADDING_THIRD_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_CANADA,
        value: REAL_TIME_COVERAGE_REGION_CANADA,
        padding: PADDING_THIRD_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_EUROPE,
        value: REAL_TIME_COVERAGE_REGION_EUROPE,
        padding: PADDING_THIRD_LEVEL,
    },
    {
        label: REAL_TIME_COVERAGE_REGION_ASIA_PACIFIC,
        value: REAL_TIME_COVERAGE_REGION_ASIA_PACIFIC,
        padding: PADDING_THIRD_LEVEL,
    },
    {
        label: REAL_TIME_COVERAGE_REGION_EMERGING_MARKETS,
        value: REAL_TIME_COVERAGE_REGION_ALL_FX_EMERGING_MARKETS_VALUE,
        padding: PADDING_SECOND_LEVEL,
        fontWeight: 'bold'
    },
    {
        label: REAL_TIME_COVERAGE_REGION_ASIA,
        value: REAL_TIME_COVERAGE_REGION_ASIA,
        padding: PADDING_THIRD_LEVEL,
    },
    {
        label: REAL_TIME_COVERAGE_REGION_EMEA2,
        value: REAL_TIME_COVERAGE_REGION_EMEA2,
        padding: PADDING_THIRD_LEVEL,
    },
    {
        label: REAL_TIME_COVERAGE_REGION_LATAM_LABEL,
        value: REAL_TIME_COVERAGE_REGION_LATAM_VALUE,
        padding: PADDING_THIRD_LEVEL,
    }
];

export const REAL_TIME_COVERAGE_REGION_OPTIONS_COMMODITIES = [
    {
        label: REAL_TIME_COVERAGE_REGION_ALL_LABEL,
        value: REAL_TIME_COVERAGE_REGION_ALL_COMMODITIES_VALUE,
        padding: PADDING_FIRST_LEVEL,
        fontWeight: 'bold'
    },
    {
        label: REAL_TIME_COVERAGE_REGION_ENERGY,
        value: REAL_TIME_COVERAGE_REGION_ENERGY,
        padding: PADDING_THIRD_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_PRECIOUS_METALS,
        value: REAL_TIME_COVERAGE_REGION_PRECIOUS_METALS,
        padding: PADDING_THIRD_LEVEL
    },
    {
        label: REAL_TIME_COVERAGE_REGION_BASE_METALS,
        value: REAL_TIME_COVERAGE_REGION_BASE_METALS,
        padding: PADDING_THIRD_LEVEL
    }
];

export const REAL_TIME_COVERAGE_REGION_OPTIONS_PRODUCT_TYPES = {
    RATES: REAL_TIME_COVERAGE_REGION_OPTIONS_RATES,
    EMERGING_MARKETS: REAL_TIME_COVERAGE_REGION_OPTIONS_EM,
    FX: REAL_TIME_COVERAGE_REGION_OPTIONS_FX,
    COMMODITIES: REAL_TIME_COVERAGE_REGION_OPTIONS_COMMODITIES,
    MACRO: REAL_TIME_COVERAGE_REGION_OPTIONS_MACRO
}

export const RTC_DOC_TYPE_URL_PARAM = "rtcDocType";
export const RTC_REGION_URL_PARAM = "rtcRegion";
export const RTC_COMMENTARY_GDP_DOC_TYPE_NAME= "event_name_(gdp)";