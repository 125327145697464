import {
  LINK_HOME,
  LINK_PATH_ANALYSTS_PAGE,
  LINK_CALENDER,
  LINK_EQUITIES_INDEX_MARKET_STRUCTURES_PAGE,
  LINK_DESK_RESEARCH_EXCHANGE_TRADED_FUNDS_PAGE,
  LINK_REGIONS_US_PAGE,
  LINK_REGIONS_CANADA_PAGE,
  LINK_REGIONS_ASIA_PACIFIC_PAGE,
  LINK_REGIONS_EUROPE_PAGE,
  LINK_REGIONS_EMEA_PAGE,
  LINK_REGIONS_ASIA_PAGE,
  LINK_REGIONS_LATIN_AMERICA_PAGE,
  LINK_DESK_RESEARCH_ASSET_SECURITIZATION,
  LINK_CREDIT_INVESTMENT_GRADE,
  LINK_ANALYTICS_TRADE_GLOBAL_STRATEGY,
  LINK_PATH_ANALYST_TEAMS_PAGE,
  LINK_COMMODITIES_PAGE,
  LINK_EMERGING_MARKETS_PAGE,
  LINK_FX_PAGE,
  LINK_LOGGEDOUT,
  LINK_MACRO_PAGE,
  LINK_RATES_PAGE,
  LINK_EQUITIES_OVERVIEW_PAGE,
  LINK_EQUITIES_RESEARCH_ANALYST_PAGE,
  LINK_EQUITIES_RESEARCH_INDUSTRY_PAGE,
  LINK_EQUITIES_RESEARCH_COMPANY_PAGE,
  LINK_EQUITIES_COVERAGE_COMPANY_PAGE,
  LINK_EQUITIES_COVERAGE_ACTION_LIST_PAGE,
  LINK_EQUITIES_US_RESEARCH_ANALYST_PAGE,
  LINK_EQUITIES_US_OVERVIEW_PAGE,
  LINK_EQUITIES_US_RESEARCH_COMPANY_PAGE,
  LINK_EQUITIES_US_RESEARCH_INDUSTRY_PAGE,
  LINK_EQUITIES_WRG_OVERVIEW_PAGE,
  LINK_EQUITIES_WRG_RESEARCH_ANALYST_PAGE,
  LINK_EQUITIES_WRG_RESEARCH_INDUSTRY_PAGE,
  LINK_EQUITIES_CA_OVERVIEW_PAGE,
  LINK_EQUITIES_CA_RESEARCH_ANALYST_PAGE,
  LINK_EQUITIES_CA_RESEARCH_COMPANY_PAGE,
  LINK_EQUITIES_CA_RESEARCH_INDUSTRY_PAGE,
  LINK_EQUITIES_CONFERENCE_CALLS_OTHER_MEDIA_PAGE,
  LINK_DESK_RESEARCH_CREDIT_INVESTMENT_GRADE
} from "./LinkPaths.constants";

import {
  ACL_CALENDAR,
  ACL_LOGGED_OUT,
  ACL_ANALYST_PAGE,
  ACL_ANALYST_TEAMS_PAGE,
  ACL_EQUITIES_COVERAGE,
  ACL_EQUITIES_RESEARCH,
  ACL_EQUITIES_INDEX_MARKET_STRUCTURES,
  ACL_REGIONS_US,
  ACL_REGIONS_CANADA,
  ACL_REGIONS_ASIA_PACIFIC,
  ACL_REGIONS_EUROPE,
  ACL_REGIONS_EMEA,
  ACL_REGIONS_ASIA,
  ACL_REGIONS_LATIN_AMERICA,
  ACL_PRODUCT_MACRO,
  ACL_PRODUCT_RATES,
  ACL_CREDIT_INVESTMENT_GRADE,
  ACL_PRODUCT_EMERGING_MARKETS,
  ACL_PRODUCT_FX,
  ACL_PRODUCT_COMMODITIES,
  ACL_TRADE_ANALYTICS_GLOBAL_MARKET_SNAPSHOT,
  ACL_DESK_RESEARCH_ASSET_SECURITIZATION,
  ACL_DESK_RESEARCH_EXCHANGE_TRADED_FUNDS,
  ACL_EQUITIES_OVERVIEW,
  ACL_EQUITIES_CONFERENCE_CALLS_OTHER_MEDIA,
} from "./Entitlements.constants";

// HEADER NAV CLASS NAMES BEGIN

export const TDS_TOP_NAV = "tds-top-nav";
export const TDS_TOP_NAV_RIGHT = "tds-top-nav right";
export const TDS_TOP_NAV_DROPDOWN_LINK = "tds-top-nav-dropdown-link";
export const TDS_DROPDOWN_CONTENT = "tds-dropdown-content";
export const TDS_DROPDOWN_CONTENT_USER = "tds-dropdown-content right user";
export const TDS_NAVBAR = "tds-navbar";
export const TDS_NAVBAR_DROPDOWN_LINK = "tds-navbar-dropdown-link";
export const TDS_DROPDOWN_ACTIVE = " active";

export const TDS_TOP_NAV_MOBILE = "tds-mobile-navbar";
export const TDS_ACTIVE = " active";
export const TDS_TOP_NAV_DROPDOWN_LINK_MOBILE =
  "tds-mobile-navbar-dropdown-link";
export const TDS_DROPDOWN_CONTENT_MOBILE = "tds-mobile-dropdown-content";

// HEADER NAV CLASS NAMES END

export const TYPE_DROPDOWN = "dropdown";
export const TYPE_DROPDOWN_SUB_HEADING = "dropdown-sub-heading";
export const TYPE_LINK = "link";
export const LINK_DESTINATION_INTERNAL = "internal";
export const LINK_DESTINATION_EXTERNAL = "external";
export const LINK_DESTINATION_NONE = "none";

export const MODAL_TYPE_DEFAULT = "default";
export const MODAL_TYPE_EQUITIES = "equities";
export const MODAL_TYPE_OTHER = "other";
export const MODAL_TYPE_CROSS_ASSETS_CREDIT = "cross-assets-credit";

export const AccessCondition = {
  OR: "OR",
  AND: "AND",
};

export const HeaderTopNavMenuData = [
  {
    link: LINK_HOME,
    text: "Home",
    title: "Home",
    icon: "td-icon-homepage",
    type: TYPE_LINK,
    hasAccess: true,
    modalType: MODAL_TYPE_DEFAULT,
    destination: LINK_DESTINATION_INTERNAL,
  },
  {
    link: null,
    text: "Analyst",
    title: "Analyst",
    type: TYPE_DROPDOWN,
    destination: LINK_DESTINATION_NONE,
    items: [
      {
        link: LINK_PATH_ANALYSTS_PAGE,
        text: "All Analysts",
        title: "All Analysts",
        hasAccess: ACL_ANALYST_PAGE,
        modalType: MODAL_TYPE_DEFAULT,
        destination: LINK_DESTINATION_INTERNAL,
      },
      {
        link: LINK_PATH_ANALYST_TEAMS_PAGE,
        text: "Analyst Teams",
        title: "Analyst Teams",
        hasAccess: ACL_ANALYST_TEAMS_PAGE,
        modalType: MODAL_TYPE_DEFAULT,
        destination: LINK_DESTINATION_INTERNAL,
      },
    ],
  },
  {
    link: "https://www.tdsecurities.com/ca/en/research-strategy",
    text: "About Us",
    title: "About Us",
    type: TYPE_LINK,
    hasAccess: true,
    modalType: MODAL_TYPE_DEFAULT,
    destination: LINK_DESTINATION_EXTERNAL,
  },
];

export const HeaderTopNavUserMenuData = [
  {
    link: null,
    text: "Jennifer Jacobs",
    title: "Jennifer Jacobs",
    type: TYPE_DROPDOWN,
    destination: LINK_DESTINATION_NONE,
    items: [
      {
        link: LINK_CALENDER,
        text: "Calendar",
        title: "Calendar",
        hasAccess: ACL_CALENDAR,
        modalType: MODAL_TYPE_DEFAULT,
        destination: LINK_DESTINATION_INTERNAL,
      },
      /*{
                link: LINK_PREFERENCES, 
                text: "Preferences", 
                title: "Preferences",
                hasAccess: true,
                modalType: MODAL_TYPE_DEFAULT,
                destination: LINK_DESTINATION_INTERNAL
            },*/
      {
        link: LINK_LOGGEDOUT,
        text: "Log Out",
        title: "Log Out",
        hasAccess: ACL_LOGGED_OUT,
        modalType: MODAL_TYPE_DEFAULT,
        destination: LINK_DESTINATION_INTERNAL,
      },
    ],
  },
];

export const HeaderMainNavMenuData = [
  {
    link: null,
    text: "Equities",
    title: "Equities",
    type: TYPE_DROPDOWN,
    destination: LINK_DESTINATION_NONE,
    items: [
      {
        link: LINK_EQUITIES_OVERVIEW_PAGE,
        text: "Equities Overview",
        title: "Equities Overview",
        hasAccess: ACL_EQUITIES_OVERVIEW,
        showCondition: AccessCondition.AND,
        modalType: MODAL_TYPE_EQUITIES,
        destination: LINK_DESTINATION_INTERNAL,
      },
      {
        header: "Research",
        items: [
          {
            link: LINK_EQUITIES_RESEARCH_ANALYST_PAGE,
            text: "Analyst",
            title: "Analyst",
            hasAccess: ACL_EQUITIES_COVERAGE,
            modalType: MODAL_TYPE_EQUITIES,
            destination: LINK_DESTINATION_INTERNAL,
          },
          {
            link: LINK_EQUITIES_RESEARCH_COMPANY_PAGE,
            text: "Company",
            title: "Company",
            hasAccess: ACL_EQUITIES_COVERAGE,
            modalType: MODAL_TYPE_EQUITIES,
            destination: LINK_DESTINATION_INTERNAL,
          },
          {
            link: LINK_EQUITIES_RESEARCH_INDUSTRY_PAGE,
            text: "Industry",
            title: "Industry",
            hasAccess: ACL_EQUITIES_COVERAGE,
            modalType: MODAL_TYPE_EQUITIES,
            destination: LINK_DESTINATION_INTERNAL,
          },
        ],
      },
      {
        header: "Coverage",
        items: [
          {
            link: LINK_EQUITIES_COVERAGE_COMPANY_PAGE,
            text: "Company",
            title: "Company",
            hasAccess: ACL_EQUITIES_RESEARCH,
            modalType: MODAL_TYPE_EQUITIES,
            destination: LINK_DESTINATION_INTERNAL,
          },
          {
            link: LINK_EQUITIES_COVERAGE_ACTION_LIST_PAGE,
            text: "Action List - Our Best Ideas",
            title: "Action List - Our Best Ideas",
            hasAccess: ACL_EQUITIES_RESEARCH,
            modalType: MODAL_TYPE_EQUITIES,
            destination: LINK_DESTINATION_INTERNAL,
          },
        ],
      },
      {
        link: LINK_EQUITIES_INDEX_MARKET_STRUCTURES_PAGE,
        text: "Index & Market Structure",
        title: "Index & Market Structure",
        hasAccess: ACL_EQUITIES_INDEX_MARKET_STRUCTURES,
        modalType: MODAL_TYPE_EQUITIES,
        destination: LINK_DESTINATION_INTERNAL,
      },
      // {
      //     link: LINK_EQUITIES_IMPORTANT_DISCLOSURES,
      //     text: "Important Disclosures",
      //     title: "Important Disclosures",
      //     hasAccess: false,
      //     modalType: MODAL_TYPE_EQUITIES,
      //     destination: LINK_DESTINATION_INTERNAL
      // },
      // {
      //     link: LINK_EQUITIES_COMPANY_PAGE,
      //     text: "Company Page",
      //     title: "Company Page",
      //     hasAccess: false,
      //     modalType: MODAL_TYPE_EQUITIES,
      //     destination: LINK_DESTINATION_INTERNAL
      // },
    ],
  },
  {
    link: null,
    text: "Regions",
    title: "Regions",
    type: TYPE_DROPDOWN,
    destination: LINK_DESTINATION_NONE,
    items: [
      {
        header: "Developed Markets",
        items: [
          {
            link: LINK_REGIONS_US_PAGE,
            text: "US",
            title: "US",
            hasAccess: ACL_REGIONS_US,
            modalType: MODAL_TYPE_DEFAULT,
            destination: LINK_DESTINATION_INTERNAL,
          },
          {
            link: LINK_REGIONS_CANADA_PAGE,
            text: "Canada",
            title: "Canada",
            hasAccess: ACL_REGIONS_CANADA,
            modalType: MODAL_TYPE_DEFAULT,
            destination: LINK_DESTINATION_INTERNAL,
          },
          {
            link: LINK_REGIONS_ASIA_PACIFIC_PAGE,
            text: "Asia-Pacific",
            title: "Asia-Pacific",
            hasAccess: ACL_REGIONS_ASIA_PACIFIC,
            modalType: MODAL_TYPE_DEFAULT,
            destination: LINK_DESTINATION_INTERNAL,
          },
          {
            link: LINK_REGIONS_EUROPE_PAGE,
            text: "Europe",
            title: "Europe",
            hasAccess: ACL_REGIONS_EUROPE,
            modalType: MODAL_TYPE_DEFAULT,
            destination: LINK_DESTINATION_INTERNAL,
          },
        ],
      },
      {
        header: "Emerging Markets",
        items: [
          {
            link: LINK_REGIONS_EMEA_PAGE,
            text: "EMEA",
            title: "EMEA",
            hasAccess: ACL_REGIONS_EMEA,
            modalType: MODAL_TYPE_DEFAULT,
            destination: LINK_DESTINATION_INTERNAL,
          },
          {
            link: LINK_REGIONS_ASIA_PAGE,
            text: "Asia",
            title: "Asia",
            hasAccess: ACL_REGIONS_ASIA,
            modalType: MODAL_TYPE_DEFAULT,
            destination: LINK_DESTINATION_INTERNAL,
          },
          {
            link: LINK_REGIONS_LATIN_AMERICA_PAGE,
            text: "Latin America",
            title: "Latin America",
            hasAccess: ACL_REGIONS_LATIN_AMERICA,
            modalType: MODAL_TYPE_DEFAULT,
            destination: LINK_DESTINATION_INTERNAL,
          },
        ],
      },
    ],
  },
  {
    link: LINK_MACRO_PAGE,
    text: "Macro",
    title: "Macro",
    type: TYPE_LINK,
    hasAccess: ACL_PRODUCT_MACRO,
    modalType: MODAL_TYPE_DEFAULT,
    destination: LINK_DESTINATION_INTERNAL,
  },
  {
    link: LINK_RATES_PAGE,
    text: "Rates",
    title: "Rates",
    type: TYPE_LINK,
    hasAccess: ACL_PRODUCT_RATES,
    modalType: MODAL_TYPE_DEFAULT,
    destination: LINK_DESTINATION_INTERNAL,
  },
  {
    link: null,
    text: "Credit",
    title: "Credit",
    type: TYPE_DROPDOWN,
    destination: LINK_DESTINATION_NONE,
    items: [
      /*{
        link: LINK_CREDIT_HIGH_YIELD,
        text: "High Yield",
        title: "High Yield",
        hasAccess: ACL_CREDIT_HIGH_YIELD,
        modalType: MODAL_TYPE_CROSS_ASSETS_CREDIT,
        destination: LINK_DESTINATION_INTERNAL,
      },*/
      {
        link: LINK_CREDIT_INVESTMENT_GRADE,
        text: "Investment Grade",
        title: "Investment Grade",
        hasAccess: ACL_CREDIT_INVESTMENT_GRADE,
        modalType: MODAL_TYPE_CROSS_ASSETS_CREDIT,
        destination: LINK_DESTINATION_INTERNAL,
      },
    ],
  },
  {
    link: LINK_EMERGING_MARKETS_PAGE,
    text: "Emerging Markets",
    title: "Emerging Markets",
    type: TYPE_LINK,
    hasAccess: ACL_PRODUCT_EMERGING_MARKETS,
    modalType: MODAL_TYPE_DEFAULT,
    destination: LINK_DESTINATION_INTERNAL,
  },
  {
    link: LINK_FX_PAGE,
    text: "FX",
    title: "FX",
    type: TYPE_LINK,
    hasAccess: ACL_PRODUCT_FX,
    modalType: MODAL_TYPE_DEFAULT,
    destination: LINK_DESTINATION_INTERNAL,
  },
  {
    link: LINK_COMMODITIES_PAGE,
    text: "Commodities",
    title: "Commodities",
    type: TYPE_LINK,
    hasAccess: ACL_PRODUCT_COMMODITIES,
    modalType: MODAL_TYPE_DEFAULT,
    destination: LINK_DESTINATION_INTERNAL,
  },
  {
    link: null,
    text: "Trades & Forecasts",
    title: "Trades & Forecasts",
    type: TYPE_DROPDOWN,
    destination: LINK_DESTINATION_NONE,
    items: [
      {
        link: LINK_ANALYTICS_TRADE_GLOBAL_STRATEGY,
        text: "Global Strategy",
        title: "Global Strategy",
        hasAccess: ACL_TRADE_ANALYTICS_GLOBAL_MARKET_SNAPSHOT,
        modalType: MODAL_TYPE_CROSS_ASSETS_CREDIT,
        destination: LINK_DESTINATION_INTERNAL,
      },
    ],
  },
  {
    link: null,
    text: "Desk Research",
    title: "Desk Research",
    type: TYPE_DROPDOWN,
    destination: LINK_DESTINATION_NONE,
    items: [
      {
        link: LINK_DESK_RESEARCH_ASSET_SECURITIZATION,
        text: "Asset Securitization",
        title: "Asset Securitization",
        hasAccess: ACL_DESK_RESEARCH_ASSET_SECURITIZATION,
        modalType: MODAL_TYPE_OTHER,
        destination: LINK_DESTINATION_INTERNAL,
      },
      {
        link: LINK_DESK_RESEARCH_EXCHANGE_TRADED_FUNDS_PAGE,
        text: "Exchange Traded Funds (ETFs)",
        title: "Exchange Traded Funds (ETFs)",
        hasAccess: ACL_DESK_RESEARCH_EXCHANGE_TRADED_FUNDS,
        modalType: MODAL_TYPE_EQUITIES,
        destination: LINK_DESTINATION_INTERNAL,
      },
      {
        link: LINK_DESK_RESEARCH_CREDIT_INVESTMENT_GRADE,
        text: "Investment Grade Credit",
        title: "Investment Grade",
        hasAccess: ACL_CREDIT_INVESTMENT_GRADE,
        modalType: MODAL_TYPE_CROSS_ASSETS_CREDIT,
        destination: LINK_DESTINATION_INTERNAL,
      },
    ],
  },
];

export const HeaderMainNavMenuDataCowen = [
  {
    link: null,
    text: "Equities",
    title: "Equities",
    type: TYPE_DROPDOWN,
    destination: LINK_DESTINATION_NONE,
    items: [
      {
        link: LINK_EQUITIES_CA_OVERVIEW_PAGE,
        text: "Equities Overview - CA",
        title: "Equities Overview - CA",
        hasAccess: ACL_EQUITIES_RESEARCH,
        showCondition: AccessCondition.OR,
        modalType: MODAL_TYPE_EQUITIES,
        destination: LINK_DESTINATION_INTERNAL,
      },
      {
        items: [
          {
            link: LINK_EQUITIES_CA_RESEARCH_ANALYST_PAGE,
            text: "Analyst",
            title: "Analyst",
            hasAccess: ACL_EQUITIES_RESEARCH,
            showCondition: AccessCondition.OR,
            modalType: MODAL_TYPE_EQUITIES,
            destination: LINK_DESTINATION_INTERNAL,
          },
          {
            link: LINK_EQUITIES_CA_RESEARCH_COMPANY_PAGE,
            text: "Company",
            title: "Company",
            hasAccess: ACL_EQUITIES_RESEARCH,
            showCondition: AccessCondition.OR,
            modalType: MODAL_TYPE_EQUITIES,
            destination: LINK_DESTINATION_INTERNAL,
          },
          {
            link: LINK_EQUITIES_CA_RESEARCH_INDUSTRY_PAGE,
            text: "Industry",
            title: "Industry",
            hasAccess: ACL_EQUITIES_RESEARCH,
            showCondition: AccessCondition.OR,
            modalType: MODAL_TYPE_EQUITIES,
            destination: LINK_DESTINATION_INTERNAL,
          },
        ],
      },
      {
        link: LINK_EQUITIES_US_OVERVIEW_PAGE,
        text: "Equities Overview - US",
        title: "Equities Overview - US",
        hasAccess: ACL_EQUITIES_RESEARCH,
        showCondition: AccessCondition.OR,
        modalType: MODAL_TYPE_EQUITIES,
        destination: LINK_DESTINATION_INTERNAL,
      },
      {
        items: [
          {
            link: LINK_EQUITIES_US_RESEARCH_ANALYST_PAGE,
            text: "Analyst",
            title: "Analyst",
            hasAccess: ACL_EQUITIES_RESEARCH,
            showCondition: AccessCondition.OR,
            modalType: MODAL_TYPE_EQUITIES,
            destination: LINK_DESTINATION_INTERNAL,
          },
          {
            link: LINK_EQUITIES_US_RESEARCH_COMPANY_PAGE,
            text: "Company",
            title: "Company",
            hasAccess: ACL_EQUITIES_RESEARCH,
            showCondition: AccessCondition.OR,
            modalType: MODAL_TYPE_EQUITIES,
            destination: LINK_DESTINATION_INTERNAL,
          },
          {
            link: LINK_EQUITIES_US_RESEARCH_INDUSTRY_PAGE,
            text: "Industry",
            title: "Industry",
            hasAccess: ACL_EQUITIES_RESEARCH,
            showCondition: AccessCondition.OR,
            modalType: MODAL_TYPE_EQUITIES,
            destination: LINK_DESTINATION_INTERNAL,
          },
        ],
      },
      {
        link: LINK_EQUITIES_WRG_OVERVIEW_PAGE,
        text: "Washington Research Group",
        title: "Washington Research Group",
        hasAccess: ACL_EQUITIES_RESEARCH,
        showCondition: AccessCondition.OR,
        modalType: MODAL_TYPE_EQUITIES,
        destination: LINK_DESTINATION_INTERNAL,
      },
      {
        items: [
          {
            link: LINK_EQUITIES_WRG_RESEARCH_ANALYST_PAGE,
            text: "Analyst",
            title: "Analyst",
            hasAccess: ACL_EQUITIES_RESEARCH,
            showCondition: AccessCondition.OR,
            modalType: MODAL_TYPE_EQUITIES,
            destination: LINK_DESTINATION_INTERNAL,
          },
          {
            link: LINK_EQUITIES_WRG_RESEARCH_INDUSTRY_PAGE,
            text: "Industry",
            title: "Industry",
            hasAccess: ACL_EQUITIES_RESEARCH,
            showCondition: AccessCondition.OR,
            modalType: MODAL_TYPE_EQUITIES,
            destination: LINK_DESTINATION_INTERNAL,
          },
        ],
      },
      {
        link: LINK_EQUITIES_INDEX_MARKET_STRUCTURES_PAGE,
        text: "Index & Market Structure",
        title: "Index & Market Structure",
        hasAccess: ACL_EQUITIES_INDEX_MARKET_STRUCTURES,
        modalType: MODAL_TYPE_EQUITIES,
        destination: LINK_DESTINATION_INTERNAL,
      },
      {
        link: LINK_EQUITIES_CONFERENCE_CALLS_OTHER_MEDIA_PAGE,
        text: "Conference Calls & Other Media",
        title: "Conference Calls & Other Media",
        hasAccess: ACL_EQUITIES_CONFERENCE_CALLS_OTHER_MEDIA,
        showCondition: AccessCondition.OR,
        modalType: MODAL_TYPE_EQUITIES,
        destination: LINK_DESTINATION_INTERNAL,
      },
      // {
      //     link: LINK_EQUITIES_IMPORTANT_DISCLOSURES,
      //     text: "Important Disclosures",
      //     title: "Important Disclosures",
      //     hasAccess: false,
      //     modalType: MODAL_TYPE_EQUITIES,
      //     destination: LINK_DESTINATION_INTERNAL
      // },
      // {
      //     link: LINK_EQUITIES_COMPANY_PAGE,
      //     text: "Company Page",
      //     title: "Company Page",
      //     hasAccess: false,
      //     modalType: MODAL_TYPE_EQUITIES,
      //     destination: LINK_DESTINATION_INTERNAL
      // },
    ],
  },
  {
    link: null,
    text: "Regions",
    title: "Regions",
    type: TYPE_DROPDOWN,
    destination: LINK_DESTINATION_NONE,
    items: [
      {
        header: "Developed Markets",
        items: [
          {
            link: LINK_REGIONS_US_PAGE,
            text: "US",
            title: "US",
            hasAccess: ACL_REGIONS_US,
            modalType: MODAL_TYPE_DEFAULT,
            destination: LINK_DESTINATION_INTERNAL,
          },
          {
            link: LINK_REGIONS_CANADA_PAGE,
            text: "Canada",
            title: "Canada",
            hasAccess: ACL_REGIONS_CANADA,
            modalType: MODAL_TYPE_DEFAULT,
            destination: LINK_DESTINATION_INTERNAL,
          },
          {
            link: LINK_REGIONS_ASIA_PACIFIC_PAGE,
            text: "Asia-Pacific",
            title: "Asia-Pacific",
            hasAccess: ACL_REGIONS_ASIA_PACIFIC,
            modalType: MODAL_TYPE_DEFAULT,
            destination: LINK_DESTINATION_INTERNAL,
          },
          {
            link: LINK_REGIONS_EUROPE_PAGE,
            text: "Europe",
            title: "Europe",
            hasAccess: ACL_REGIONS_EUROPE,
            modalType: MODAL_TYPE_DEFAULT,
            destination: LINK_DESTINATION_INTERNAL,
          },
        ],
      },
      {
        header: "Emerging Markets",
        items: [
          {
            link: LINK_REGIONS_EMEA_PAGE,
            text: "EMEA",
            title: "EMEA",
            hasAccess: ACL_REGIONS_EMEA,
            modalType: MODAL_TYPE_DEFAULT,
            destination: LINK_DESTINATION_INTERNAL,
          },
          {
            link: LINK_REGIONS_ASIA_PAGE,
            text: "Asia",
            title: "Asia",
            hasAccess: ACL_REGIONS_ASIA,
            modalType: MODAL_TYPE_DEFAULT,
            destination: LINK_DESTINATION_INTERNAL,
          },
          {
            link: LINK_REGIONS_LATIN_AMERICA_PAGE,
            text: "Latin America",
            title: "Latin America",
            hasAccess: ACL_REGIONS_LATIN_AMERICA,
            modalType: MODAL_TYPE_DEFAULT,
            destination: LINK_DESTINATION_INTERNAL,
          },
        ],
      },
    ],
  },
  {
    link: LINK_MACRO_PAGE,
    text: "Macro",
    title: "Macro",
    type: TYPE_LINK,
    hasAccess: ACL_PRODUCT_MACRO,
    modalType: MODAL_TYPE_DEFAULT,
    destination: LINK_DESTINATION_INTERNAL,
  },
  {
    link: LINK_RATES_PAGE,
    text: "Rates",
    title: "Rates",
    type: TYPE_LINK,
    hasAccess: ACL_PRODUCT_RATES,
    modalType: MODAL_TYPE_DEFAULT,
    destination: LINK_DESTINATION_INTERNAL,
  },
  {
    link: null,
    text: "Credit",
    title: "Credit",
    type: TYPE_DROPDOWN,
    destination: LINK_DESTINATION_NONE,
    items: [
      /*{
        link: LINK_CREDIT_HIGH_YIELD,
        text: "High Yield",
        title: "High Yield",
        hasAccess: ACL_CREDIT_HIGH_YIELD,
        modalType: MODAL_TYPE_CROSS_ASSETS_CREDIT,
        destination: LINK_DESTINATION_INTERNAL,
      },*/
      {
        link: LINK_CREDIT_INVESTMENT_GRADE,
        text: "Investment Grade",
        title: "Investment Grade",
        hasAccess: ACL_CREDIT_INVESTMENT_GRADE,
        modalType: MODAL_TYPE_CROSS_ASSETS_CREDIT,
        destination: LINK_DESTINATION_INTERNAL,
      },
    ],
  },
  {
    link: LINK_EMERGING_MARKETS_PAGE,
    text: "Emerging Markets",
    title: "Emerging Markets",
    type: TYPE_LINK,
    hasAccess: ACL_PRODUCT_EMERGING_MARKETS,
    modalType: MODAL_TYPE_DEFAULT,
    destination: LINK_DESTINATION_INTERNAL,
  },
  {
    link: LINK_FX_PAGE,
    text: "FX",
    title: "FX",
    type: TYPE_LINK,
    hasAccess: ACL_PRODUCT_FX,
    modalType: MODAL_TYPE_DEFAULT,
    destination: LINK_DESTINATION_INTERNAL,
  },
  {
    link: LINK_COMMODITIES_PAGE,
    text: "Commodities",
    title: "Commodities",
    type: TYPE_LINK,
    hasAccess: ACL_PRODUCT_COMMODITIES,
    modalType: MODAL_TYPE_DEFAULT,
    destination: LINK_DESTINATION_INTERNAL,
  },
  {
    link: null,
    text: "Trades & Forecasts",
    title: "Trades & Forecasts",
    type: TYPE_DROPDOWN,
    destination: LINK_DESTINATION_NONE,
    items: [
      {
        link: LINK_ANALYTICS_TRADE_GLOBAL_STRATEGY,
        text: "Global Strategy",
        title: "Global Strategy",
        hasAccess: ACL_TRADE_ANALYTICS_GLOBAL_MARKET_SNAPSHOT,
        modalType: MODAL_TYPE_CROSS_ASSETS_CREDIT,
        destination: LINK_DESTINATION_INTERNAL,
      },
    ],
  },
  {
    link: null,
    text: "Desk Research",
    title: "Desk Research",
    type: TYPE_DROPDOWN,
    destination: LINK_DESTINATION_NONE,
    items: [
      {
        link: LINK_DESK_RESEARCH_ASSET_SECURITIZATION,
        text: "Asset Securitization",
        title: "Asset Securitization",
        hasAccess: ACL_DESK_RESEARCH_ASSET_SECURITIZATION,
        modalType: MODAL_TYPE_OTHER,
        destination: LINK_DESTINATION_INTERNAL,
      },
      {
        link: LINK_DESK_RESEARCH_EXCHANGE_TRADED_FUNDS_PAGE,
        text: "Exchange Traded Funds (ETFs)",
        title: "Exchange Traded Funds (ETFs)",
        hasAccess: ACL_DESK_RESEARCH_EXCHANGE_TRADED_FUNDS,
        modalType: MODAL_TYPE_EQUITIES,
        destination: LINK_DESTINATION_INTERNAL,
      },
      {
        link: LINK_DESK_RESEARCH_CREDIT_INVESTMENT_GRADE,
        text: "Investment Grade Credit",
        title: "Investment Grade",
        hasAccess: ACL_CREDIT_INVESTMENT_GRADE,
        modalType: MODAL_TYPE_CROSS_ASSETS_CREDIT,
        destination: LINK_DESTINATION_INTERNAL,
      },
    ],
  },
];

export const HeaderMobileUserNavData = [
  {
    link: LINK_CALENDER,
    text: "Calendar",
    title: "Calendar",
    type: TYPE_LINK,
    hasAccess: ACL_CALENDAR,
    modalType: MODAL_TYPE_DEFAULT,
    destination: LINK_DESTINATION_INTERNAL,
  },
  /*{
        link: LINK_PREFERENCES,
        text: "Preferences",
        title: "Preferences",
        type: TYPE_LINK,
        hasAccess: true,
        modalType: MODAL_TYPE_DEFAULT,
        destination: LINK_DESTINATION_INTERNAL
    },*/
  {
    link: LINK_LOGGEDOUT,
    text: "Log out",
    title: "Log out",
    type: TYPE_LINK,
    hasAccess: ACL_LOGGED_OUT,
    modalType: MODAL_TYPE_DEFAULT,
    destination: LINK_DESTINATION_INTERNAL,
  },
];
