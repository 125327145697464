import React from 'react';
import { Redirect } from 'react-router-dom';
import Spinner from "../spinner/Spinner.component";
import ErrorComponent from "../misc/error/Error.component";
import IframeComponent from "../iframe/Iframe.component";
import SideBar from "../side-bar/SideBar.component";
import MailtoComponent from '../misc/mailto/Mailto.component';
import AccessDeniedComponent from '../misc/access-denied/AccessDenied.component';
import ContentService from "../../services/Content.service";
import { buildSecureLink } from '../../services/SecureLink.service';
import UtilsService from '../../services/Utils.service';
import {
    PUBLICATIONS_PAGE_TITLE
} from "../../constants/PageTitle.constants";
import {
    LINK_PATH_404_PAGE
} from '../../constants/LinkPaths.constants';
import {
    EMAIL_DELIMITER, FIRM_NAME_STRATEGY
} from '../../constants/Misc.constants';

export default class PublicationPageComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: null,
            loading: true,
            error: false,
            redirect: false,
            unauthorized: false
        };
        this.getContent = this.getContent.bind(this);
        this.getContentThen = this.getContentThen.bind(this);
        this.getContentError = this.getContentError.bind(this);
    }
    componentDidMount() {
        document.title = PUBLICATIONS_PAGE_TITLE;
        this.getContent();
    }
    componentDidUpdate(prevProps) {
        let { match } = this.props;
        if (prevProps.match && (prevProps.match.params.contentId !== this.props.match.params.contentId ||
            prevProps.match.params.firmId !== this.props.match.params.firmId
        )) {
            this.setState({ loading: true, error: false });
            ContentService.getContent(match.params.contentId, match.params.firmId)
                .then(this.getContentThen)
                .catch(this.getContentError);
        }
    }
    getContent() {
        let { match } = this.props;
        if (match && match.params && match.params.contentId && match.params.firmId) {
            ContentService.getContent(match.params.contentId, match.params.firmId)
                .then(this.getContentThen)
                .catch(this.getContentError);
        } else {
            this.getContentError(null);
        }
    }
    getContentThen(content) {
        var _content = content;
        if (_content.secureHtmlLink) {
            _content.secureHtmlLink = buildSecureLink(_content.secureHtmlLink);
        }
        this.setState({
            content: _content,
            loading: false,
        });
        document.title = PUBLICATIONS_PAGE_TITLE + this.state.content.title;
    }
    getContentError(error) {
        this.setState({
            loading: false,
            error: true,
            redirect: (error && error.status === 403) ? false : true,
            unauthorized: (error && error.status === 403) ? true : false,
        });
    }
    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to={LINK_PATH_404_PAGE} />
        }
    }
    buildShareLink = () => {
        return UtilsService.buildShareLink(this.state.content.firmName, this.state.content.contentId)
    }
    isNotBanned(id, firmName) {
        if (firmName === FIRM_NAME_STRATEGY) {
            if (id === "26" || id === "27") {
                return false;
            }
        }
        return true;
    }
    render() {
        return (
            <div className="tds-publication-page">
                {this.renderRedirect()}
                {this.state.loading && <Spinner minHeight={1200} />}
                {!this.state.loading && this.state.error && this.state.unauthorized && <AccessDeniedComponent shouldLogout="NOPE" />}
                {!this.state.loading && this.state.error && !this.state.unauthorized && <ErrorComponent />}
                {
                    !this.state.loading && this.state.content &&
                    <React.Fragment>
                        <div className="tds-publication-share-container">
                            <MailtoComponent
                                email={''}
                                titleText={'Email'}
                                icon={true}
                                className={'email-icon-share'}
                                subject={this.state.content.title}
                                body={"I'd like to share this article written by TD Securities with you." + EMAIL_DELIMITER + this.buildShareLink()}
                            />
                            <MailtoComponent
                                email={''}
                                titleText={'Email'}
                                icon={false}
                                text={'Email'}
                                className={'email-text-share'}
                                subject={this.state.content.title}
                                body={"I'd like to share this article written by TD Securities with you." + EMAIL_DELIMITER + this.buildShareLink()}
                            />
                            {
                                this.isNotBanned(this.state.content.bluematrixXmlID, this.state.content.firmName) &&
                                <React.Fragment>
                                    <a
                                        className={'pdf-icon-share'}
                                        href={buildSecureLink(this.state.content.securePdfLink)}
                                        title="View PDF"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <span
                                            className="td-icon td-icon-insights icon-small"
                                            data-firmid={this.state.content.firmId}
                                            data-productid={this.state.content.productId}
                                            data-filename={this.state.content.title}
                                        ></span>
                                    </a>
                                    <a
                                        className={'pdf-text-share'}
                                        href={buildSecureLink(this.state.content.securePdfLink)}
                                        title="View PDF"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <span
                                            className={'pdf-text-share'}
                                            data-firmid={this.state.content.firmId}
                                            data-productid={this.state.content.productId}
                                            data-filename={this.state.content.title}
                                        >
                                            PDF
                                        </span>
                                    </a>
                                </React.Fragment>
                            }
                        </div>
                        <IframeComponent src={this.state.content.secureHtmlLink || this.state.content.htmlLink} />
                        <div className="tds-publication-page-sidebar-container">
                            <SideBar
                                publicationPage={true}
                                publicationContent={this.state.content}
                            />
                        </div>
                    </React.Fragment>
                }
            </div>
        )
    }
}