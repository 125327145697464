import React from "react";
import ResearchContentComponent from "./ResearchContent.component";
import { TEAM } from "../../../constants/Misc.constants";

const ResearchContentListComponent = ({ latestContentByTypes, region }) => {

  const getSupplementalResearchData = (latestContent) => {
    let content = [];
    content.push({title: "Coverage Universe", pdfLink: latestContent});
    return content[0];
  }
  return (
    <div className="tds-research-content-list">
      {latestContentByTypes?.actionNotes && region !== TEAM.WRG && (
        <ResearchContentComponent
          name={"Company Note"}
          type="Company Note"
          content={latestContentByTypes.actionNotes}
          team={region}
        ></ResearchContentComponent>
      )}
      {latestContentByTypes?.bulletins && region !== TEAM.WRG && (
        <ResearchContentComponent
          name={
            !region || region === TEAM.CA ? "Bulletins" : "Morning Call / Edge"
          }
          type={region === TEAM.CA ? "Company Bulletin,Industry Bulletin" : "Morning Call - US,Edge"}
          content={latestContentByTypes.bulletins}
          team={region}
          hideAuthor={true}
        ></ResearchContentComponent>
      )}
      {latestContentByTypes?.industryResearch && (
        <ResearchContentComponent
          name={
            !region || region === TEAM.CA
              ? "Research Reports / Industry Notes"
              : "Research Reports / Industry Notes"
          }
          type="Research Report,Industry Note"
          content={latestContentByTypes.industryResearch}
          team={region}
        ></ResearchContentComponent>
      )}

      {latestContentByTypes?.actionNotes && region === TEAM.WRG && (
        <ResearchContentComponent
          name={"Research Reports / Industry Notes"}
          type="WRG Sector Note"
          content={latestContentByTypes.actionNotes}
          team={region}
        ></ResearchContentComponent>
      )}
      {latestContentByTypes?.bulletins && region === TEAM.WRG && (
        <ResearchContentComponent
          name={"Joint Note"}
          type="Joint WRG/Cowen Note"
          content={latestContentByTypes.bulletins}
          team={region}
        ></ResearchContentComponent>
      )}
      {latestContentByTypes?.actionList && region !== TEAM.WRG && (
        <ResearchContentComponent
          name={ !region || region === TEAM.CA
            ? "Supplemental Research"
            : "Ahead of Curve" }
          type={!region || region === TEAM.CA
            ? "Supplemental Research"
            : "Industry Note" }
          content={latestContentByTypes.actionList}
          team={region}
        ></ResearchContentComponent>
      )}
      {latestContentByTypes?.investmentStrategy && region !== TEAM.WRG && (
        <ResearchContentComponent
          name={
            !region || region === TEAM.CA
              ? "Investment Strategy"
              : "Best Ideas Packet"
          }
          type={region === TEAM.CA ? "Investment Strategy" : "Best Ideas Packet"}
          content={latestContentByTypes.investmentStrategy}
          team={region}
        ></ResearchContentComponent>
      )}
      {latestContentByTypes?.supplementalResearch && region !== TEAM.WRG && (
        <ResearchContentComponent
          name={"Coverage Universe"}
          type={""}
          content={getSupplementalResearchData(latestContentByTypes.supplementalResearch)}
          team={region}
        ></ResearchContentComponent>
      )}
    </div>
  );
};

export default ResearchContentListComponent;
