import React from "react";

/**
 * Displays key value pairs in a table format.
 * @param {*} props.contained - set contained to true to hide the separator for the last row
 */
const KeyValueTableComponent = (props) => {
  const { list, contained, className, headerLabel } = props;
  const hideLastSeparator = () => contained;

  const renderTable = (tableProps) =>    
    <table>
      <thead>
        <tr>
        {
          tableProps.header.map((item, index) => <th key={index}>{item}</th>)
        }
        </tr>
      </thead>
      <tbody>
        {
          tableProps.rows.map((row, rowIndex) =>
            <tr key={rowIndex}>
              {
                row.map((col, colIndex) => <td key={colIndex}>{col}</td>)
              }
            </tr>
          )
        }
      </tbody>

    </table>
    


  return (
    <section className={`tds-sn-key-value-table-container ${className || null}`}>
      <div style={{fontWeight:"bold", fontSize:"18px"}}>{headerLabel}</div>
      <dl className={hideLastSeparator() ? "hide-last-border": null}>
        
        {list?.map((item, index) => (
          <div key={index}>
            <dt>{item.key}</dt>
            { item?.type === 'table' && <dd> {renderTable(item.value)} </dd> }
            { !item?.type && <dd>{item.value}</dd> }
          </div>
        ))}
      </dl>
    </section>
  );
};

export default KeyValueTableComponent;
