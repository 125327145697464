import React from 'react';
import UtilsService from '../../../services/Utils.service';
import { RTC_COMMENTARY_GDP_DOC_TYPE_NAME } from '../../../constants/RealTimeCoverage.constants';

export default class RealTimeCoveragePreviewItemCompoonent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <React.Fragment>
                {   this.props.data &&
                    <div className="tds-product-preview-table-real-time-coverage-row-section">
                        <div className="tds-product-preview-table-real-time-coverage-row">
                            <div className="tds-product-preview-table-real-time-coverage-column arrow">
                                { (!(UtilsService.isHawkish(this.props.data.hawkishdovishArrow) || UtilsService.isDovish(this.props.data.hawkishdovishArrow))) &&
                                    <span>&nbsp;</span>
                                }
                                { UtilsService.isHawkish(this.props.data.hawkishdovishArrow) &&
                                    <img className="tds-product-table-real-time-coverage-arrow" src="/assets/img/Up-Arrow-Icon.svg" alt="TDS Arrow" />
                                }
                                { UtilsService.isDovish(this.props.data.hawkishdovishArrow) &&
                                    <img className="tds-product-table-real-time-coverage-arrow" src="/assets/img/Down-Arrow-Icon.svg" alt="TDS Arrow" />
                                }
                            </div>
                            <div className={this.props.data?.key !== RTC_COMMENTARY_GDP_DOC_TYPE_NAME ? "tds-product-preview-table-real-time-coverage-column event" : "tds-product-preview-table-real-time-coverage-column eventgdp"}>
                                {this.props.data.event}
                                {!this.props.data.event && <React.Fragment>&nbsp;</React.Fragment>}
                            </div>
                            { this.props.data?.key !== RTC_COMMENTARY_GDP_DOC_TYPE_NAME && 
                                <>
                                    <div className="tds-product-preview-table-real-time-coverage-column consensus">
                                        {this.props.data.consenus}
                                        {!this.props.data.consenus && <React.Fragment>&nbsp;</React.Fragment>}
                                    </div>
                                    <div className="tds-product-preview-table-real-time-coverage-column tdforecast">
                                            {this.props.data.tdForecast}
                                            {!this.props.data.tdForecast && <React.Fragment>&nbsp;</React.Fragment>}
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }
}
