import React from 'react';
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchResultsComponent from './SearchResults.component';
import Select from 'react-select';
import Spinner from '../../spinner/Spinner.component';
import SearchService from '../../../services/v2/Search.service';
import PaginationComponent from '../../pagination/Paginator.component';
import queryString from 'query-string';
import {
    ERROR_TYPE,
    FIRM_NAME_CA,
    FIRM_NAME_COWEN,
    FIRM_NAME_STRATEGY,
    FIRM_NAME_US,
    FIRM_NAME_WA,
    KEY_ENTER, PARAMS_DATE_FORMAT,
    TEAM
} from '../../../constants/Misc.constants';
import ErrorComponent from '../../misc/error/Error.component';
import UserService from '../../../services/User.service';
import {
    ACL_SEARCH_SYMBOL,
    ACL_SEARCH_INDUSTRY,
    ACL_EQUITIES_OVERVIEW
} from '../../../constants/Entitlements.constants';
import {
    ROUTE_SEARCH_PAGE
} from '../../../constants/RoutePaths.constants';
import {
    isTablet,
    isMobileOnly
} from "react-device-detect";
import {
    SEARCH_RESULTS_KEYWORDS_PLACEHOLDER_DESKTOP,
    SEARCH_RESULTS_KEYWORDS_PLACEHOLDER_TABLET,
    SEARCH_RESULTS_KEYWORDS_PLACEHOLDER_MOBILE,
    SEARCH_RESULTS_PAGE_SIZE,
    SEARCH_RESULTS_DESC,
    SEARCH_RESULTS_ASC,
    SEARCH_RESULTS_ORDER_BY_DATE,
    SEARCH_RESULTS_ORDER_BY_DOCUMENT_TYPE,
    SEARCH_RESULTS_ORDER_BY_ANALYST,
    SEARCH_RESULTS_ORDER_BY_TITLE,
    SEARCH_RESULTS_SORTBY
} from '../../../constants/SearchResults.constants';
import {
    SEARCH_PAGE_TITLE
} from '../../../constants/PageTitle.constants';
import { ReactSelectDesktopStyles } from '../../../constants/ReactSelect.constants';
import { SearchFirms } from './Search.constants';
import moment from "moment/moment";

const intialState = {
    currentPage: 1,
    isNoSearchYet: true,
    isFirstSearch: true,
    isParamSearch: false,
    keywords: null,
    symbol: null,
    showFilter: false,
    startDate: null,
    endDate: null,
    maxStartDate: new Date(),
    direction: SEARCH_RESULTS_DESC,
    minEndDate: null,
    analysts: null,
    products: null,
    documentTypes: null,
    regions: null,
    industry: null,
    searchResults: null,
    dropDownData: null,
    filteredDropDownData: null,
    dropDownLoading: true,
    searchLoading: false,
    errorLoadingDropDownData: false,
    errorLoadingSearchResultsData: false,
    orderBy: SEARCH_RESULTS_ORDER_BY_DATE,
    firmName: FIRM_NAME_COWEN,
    firmNameV2: FIRM_NAME_CA,
    team: TEAM.CA
}

class SearchComponent extends React.Component {

    _isMounted = false;
    maxDate = new Date();
    constructor(props) {
        super(props);
        this.state = intialState;
        this.hasAccessSymbol = UserService.hasAccess(ACL_SEARCH_SYMBOL);
        this.hasAccessIndustry = UserService.hasAccess(ACL_SEARCH_INDUSTRY);
        this.hasAccessFicmEqToggle = UserService.hasAccess(ACL_EQUITIES_OVERVIEW);

        this.setNullState = this.setNullState.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleKeywordsChange = this.handleKeywordsChange.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleStartDateCalenderIconClick = this.handleStartDateCalenderIconClick.bind(this);
        this.handleEndDateCalenderIconClick = this.handleEndDateCalenderIconClick.bind(this);
        this.handleProductChange = this.handleProductChange.bind(this);
        this.handleRegionChange = this.handleRegionChange.bind(this);
        this.handleIndustryChange = this.handleIndustryChange.bind(this);
        this.handleDocumentTypesChange = this.handleDocumentTypesChange.bind(this);
        this.handleAnalystsChange = this.handleAnalystsChange.bind(this);
        this.handleRegionsChange = this.handleRegionsChange.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.updateUrl = this.updateUrl.bind(this);
        this.goToPage = this.goToPage.bind(this);
        this.goToPageOne = this.goToPageOne.bind(this);
        this.getDropDown = this.getDropDown.bind(this);
        this.getDropDownData = this.getDropDownData.bind(this);
        this.getDropDownDataThen = this.getDropDownDataThen.bind(this);
        this.getDropDownDataError = this.getDropDownDataError.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleSearchData = this.handleSearchData.bind(this);
        this.handleSearchDataThen = this.handleSearchDataThen.bind(this);
        this.handleSearchDataError = this.handleSearchDataError.bind(this);
        this.handleFicmEqToggle = this.handleFicmEqToggle.bind(this);
        this.handleFilterButtonClick = this.handleFilterButtonClick.bind(this);
        this.getFirmsDetail = this.getFirmsDetail.bind(this);
        if (UserService.isEQOnly()) {
            this.state.firmName = FIRM_NAME_COWEN;
        }

        this.changeSort = this.changeSort.bind(this);
        if (isMobileOnly) {
            this.keywordsPlaceholder = SEARCH_RESULTS_KEYWORDS_PLACEHOLDER_MOBILE;
        } else if (isTablet) {
            this.keywordsPlaceholder = SEARCH_RESULTS_KEYWORDS_PLACEHOLDER_TABLET;
        } else {
            this.keywordsPlaceholder = SEARCH_RESULTS_KEYWORDS_PLACEHOLDER_DESKTOP;
        }
        if (this.props.hideSearchBar) {
            this.props.hideSearchBar();
        }
        if(UserService.isFicmOnly()) {
            this.state.team = "strategy";
            this.state.firmName = FIRM_NAME_STRATEGY;
            this.state.firmNameV2 = FIRM_NAME_STRATEGY;
        }
    }

    componentDidMount() {
        this._isMounted = true;
        document.title = SEARCH_PAGE_TITLE;

        this.getDropDown();
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    setNullState = () => {
        this.setState(intialState);
    }
    getDropDown() {
        this.setState({
            dropDownLoading: false,
            errorLoadingDropDownData: false
        }, this.getDropDownData);
    }
    getDropDownData() {
        let urlQueryParams = queryString.parse(this.props.location.search);

        let team = this.state.team;
        if (this.state.isFirstSearch && urlQueryParams.team) {
            team = urlQueryParams.team;
        }

        SearchService.getDropDownData(team).then(this.getDropDownDataThen).catch(this.getDropDownDataError);
    }
    getDropDownDataThen(dropDownData) {
        let urlQueryParams = queryString.parse(this.props.location.search);
        let state = {
            firmName: this.state.firmName,
            firmNameV2: this.state.firmNameV2
        }

        if (this.state.isFirstSearch && urlQueryParams.team) {
           switch(urlQueryParams.team){
                case "strategy":
                    state.firmName = FIRM_NAME_STRATEGY;
                    state.firmNameV2 = FIRM_NAME_STRATEGY;
                    break;
                case "ca":
                    state.firmName = FIRM_NAME_COWEN;
                    state.firmNameV2 = FIRM_NAME_CA;
                    break;
                case "us":
                    state.firmName = FIRM_NAME_COWEN;
                    state.firmNameV2 = FIRM_NAME_US;
                    break;
                default: // "wrg"
                    state.firmName = FIRM_NAME_COWEN;
                    state.firmNameV2 = FIRM_NAME_WA;
            }
        }

        let _filteredDropDownData = SearchService.filterDropDownDataByFirmName(dropDownData, state.firmName);

        if (this._isMounted) {
            const showFilterFlag = (urlQueryParams.startDate || urlQueryParams.endDate
                || urlQueryParams.analysts || urlQueryParams.products || urlQueryParams.industries || urlQueryParams.regions
                || urlQueryParams.industries || urlQueryParams.documentTypes) !== undefined;

            if (
                this.state.isFirstSearch &&
                (urlQueryParams.team || urlQueryParams.keywords || urlQueryParams.symbols || showFilterFlag)) {
                state = {
                    ...state,
                    showFilter: showFilterFlag,
                    dropDownData: dropDownData,
                    triggerSearchAfterDropdown: true,
                    isParamSearch: true,
                    filteredDropDownData: _filteredDropDownData,
                    dropDownLoading: false,
                    isFirstSearch: false
                }

                if (urlQueryParams.keywords) {
                    state.keywords = urlQueryParams.keywords;
                }
                if (urlQueryParams.documentTypes) {
                    state.documentTypes = urlQueryParams.documentTypes;
                }
                if (urlQueryParams.documentTypesMatchExact) {
                    state.documentTypesMatchExact = urlQueryParams.documentTypesMatchExact;
                }
                if (urlQueryParams.team) {
                    state.team = urlQueryParams.team;
                }
                if (urlQueryParams.symbols) {
                    var symbolData = [];
                    for (const symbolValue of urlQueryParams.symbols.split(",")) {
                        for (const symbol of dropDownData.symbols) {
                            if (symbolValue === symbol.value.toString()) {
                                symbolData.push(symbol);
                            }
                        }
                    }
                    state.symbol = symbolData;
                }
                if (urlQueryParams.startDate) {
                    state.startDate = moment(urlQueryParams.startDate, PARAMS_DATE_FORMAT).toDate();
                    state.minEndDate = state.startDate;
                }
                if (urlQueryParams.endDate) {
                    state.endDate = moment(urlQueryParams.endDate, PARAMS_DATE_FORMAT).toDate();
                    state.maxStartDate = state.endDate;
                }
                if (urlQueryParams.analysts) {
                    var analystData = [];
                    for (const analystValue of urlQueryParams.analysts.split("|")) {
                        for (const analyst of dropDownData.analysts) {
                            if (analystValue === analyst.label.toString()) {
                                analystData.push(analyst);
                            }
                        }
                    }
                    state.analysts = analystData;


                }
                if (urlQueryParams.products) {
                    var productData = [];
                    for (const productValue of urlQueryParams.products.split(",")) {
                        for (const product of dropDownData.products) {
                            if (productValue === product.value.toString()) {
                                productData.push(product);
                            }
                        }
                    }
                    state.products = productData;
                }
                if (urlQueryParams.industries) {
                    var industryData = [];
                    for (const industryValue of urlQueryParams.industries.split(",")) {
                        for (const industry of dropDownData.industries) {
                            if (industryValue === industry.value.toString()) {
                                industryData.push(industry);
                            }
                        }
                    }
                    state.industry = industryData;
                }
                if (urlQueryParams.regions) {
                    var regionData = [];
                    for (const regionValue of urlQueryParams.regions.split(",")) {
                        for (const region of dropDownData.regions) {
                            if (regionValue === region.label.toString()) {
                                regionData.push(region);
                            }
                        }
                    }
                    state.regions = regionData;
                }
                if (urlQueryParams.documentTypes) {
                    var documentTypesData = [];
                    for (const documentTypesValue of urlQueryParams.documentTypes.split(",")) {
                        for (const documentType of _filteredDropDownData.documentTypes) {
                            if (documentTypesValue === documentType.label.toString()) {
                                documentTypesData.push(documentType);
                            }
                        }
                    }
                    state.documentTypes = documentTypesData;
                }

                this.setState(state, () => {this.goToPage(1)});
            } else {
                this.setState({
                    dropDownData: dropDownData,
                    filteredDropDownData: _filteredDropDownData,
                    dropDownLoading: false
                });
            }
        }
    }

    getDropDownDataError(error) {
        this.setState({
            dropDownData: null,
            dropDownLoading: false,
            errorLoadingDropDownData: true
        });
    }
    goToPage(page) {
        this.setState(
            {
                currentPage: page
            },
            this.handleSearch
        );
    }
    goToPageOne() {
        this.setState({errorLoadingSearchData : false});
        this.goToPage(1);
    }
    handleFilterButtonClick = (event) => {
        this.setState({
            showFilter: !this.state.showFilter,
            startDate: null,
            endDate: null,
            analysts: null,
            products: null,
            documentTypes: null,
            regions: null,
            industry: null
        });
    }
    changeSort(sort) {
        var _direction = SEARCH_RESULTS_DESC;
        if (sort === SEARCH_RESULTS_ORDER_BY_DOCUMENT_TYPE ||
            sort === SEARCH_RESULTS_ORDER_BY_TITLE ||
            sort === SEARCH_RESULTS_ORDER_BY_ANALYST) {
            _direction = SEARCH_RESULTS_ASC;
        }
        this.setState(
            {
                orderBy: sort,
                direction: _direction

            },
            () => {this.goToPage(1)}
        );
    }
    updateUrl(fields) {
        var url = ROUTE_SEARCH_PAGE;
        var params = {};
        var paramsString = "";

        if (this.state.keywords) {
            params.keywords = this.state.keywords;
        }
        if (this.state.team) {
            params.team = this.state.team;
        }
        if (fields.symbols) {
           params.symbols = fields.symbols.toString();
        }
        if (fields.startDate) {
            params.startDate = fields.startDate;
        }
        if (fields.endDate) {
            params.endDate = fields.endDate;
        }
        if (fields.analysts) {
            params.analysts = fields.analystsUrlParam;
        }
        if (fields.products) {
            params.products = fields.products;
        }
        if (fields.industries) {
            params.industries = fields.industries.toString();
        }
        if (fields.regions) {
            params.regions = fields.regionsUrlParam;
        }
        if (fields.documentTypes) {
            params.documentTypes = fields.documentTypesUrlParam;
        }

        var paramsKeys = Object.keys(params);
        for (var i = 0; i < paramsKeys.length; i++) {
            paramsString +=
                (paramsString === "" ? "" : "&")
                + paramsKeys[i] + "=" + params[paramsKeys[i]];
        }
        url = url + "?" + paramsString;

        this.props.history.push(url);
    }
    handleKeywordsChange = (event) => {
        this.setState(
            { keywords: event.target.value }
        );
    }
    handleSymbolChange = value => {
        this.setState({ symbol: value });
    }
    handleStartDateChange = date => {
        this.setState(
            {
                startDate: date,
                minEndDate: date
            }
        );
    };
    handleEndDateChange = date => {
        this.setState(
            {
                endDate: date,
                maxStartDate: date
            }
        );
    };
    handleStartDateCalenderIconClick = (event) => {
        this.startDateCalendar.setOpen(true);
    }
    handleEndDateCalenderIconClick = (event) => {
        this.endDateCalendar.setOpen(true);
    }
    handleProductChange = value => {
        this.setState({ products: value });
    }
    handleRegionChange = value => {
        this.setState({ regions: value });
    }
    handleIndustryChange = value => {
        this.setState({ industry: value });
    }
    handleDocumentTypesChange = value => {
        this.setState({ documentTypes: value });
    }
    handleAnalystsChange = value => {
        this.setState({ analysts: value });
    }
    handleRegionsChange = value => {
        this.setState({ regions: value });
    }
    handleCancel = () => {
        this.maxDate = new Date();
        this.setState({
            keywords: null,
            symbol: null,
            startDate: null,
            endDate: null,
            analysts: null,
            products: null,
            documentTypes: null,
            minEndDate: null,
            maxStartDate: new Date(),
            regions: null,
            industry: null,
            results: null,
            sort: SEARCH_RESULTS_SORTBY
        });
    }

    handleSearch() {
        var params = {
            value: this.state.keywords,
            pubFrom: this.state.startDate,
            pubTo: this.state.endDate,
            page: this.state.currentPage,
            size: SEARCH_RESULTS_PAGE_SIZE,
            direction: this.state.direction,
            contentSearchTypeOrder: this.state.orderBy,
            firm: this.state.firmName,
            exactFirm: this.state.firmNameV2
        };
        if ((UserService.isFicm() && !UserService.isEQ()) || this.state.firmName === FIRM_NAME_STRATEGY) {
            params.firm = FIRM_NAME_STRATEGY;
        } else if (UserService.isEQ() && !UserService.isFicm()) {
            params.firm = FIRM_NAME_COWEN;
        }

        // Apply Entitlements
        if (!this.hasAccessSymbol) {
            params.symbols = null;
        }
        if (!this.hasAccessIndustry) {
            params.industries = null;
        }

        if (this.state.symbol) {
            params.symbols = this.convertValues(this.state.symbol);
        }
        if (this.state.startDate) {
            params.startDate = moment(this.state.startDate).format(PARAMS_DATE_FORMAT)
        }
        if (this.state.endDate) {
            params.endDate = moment(this.state.endDate).format(PARAMS_DATE_FORMAT)
        }
        if (this.state.analysts) {
            params.analysts = this.convertValues(this.state.analysts, true);
            params.analystsUrlParam = this.state.analysts.map(d => d.label).join("|").toString();
        }
        if (this.state.products) {
            params.products = this.convertValues(this.state.products);
        }
        if (this.state.industry) {
            params.industries = this.convertValues(this.state.industry);
        }
        if (this.state.regions) {
            params.regions = this.convertValues(this.state.regions);
            params.regionsUrlParam = this.state.regions.map(d => d.label).join(",").toString();
        }
        if (this.state.documentTypes) {
            params.documentTypes = this.convertValues(this.state.documentTypes, true);
            params.documentTypesUrlParam = this.state.documentTypes.map(d => d.label).join(",").toString();
        }

        this.setState({
            searchLoading: true,
            searchResults: null,
            firstSearch: false,
            isParamSearch: false,
            isNoSearchYet: false
        }, () => {this.handleSearchData(params)});

        this.updateUrl(params);
    }
    handleSearchData(params) {
        SearchService.getSearchResults(params).then(this.handleSearchDataThen).catch(this.handleSearchDataError);
    }
    handleSearchDataThen(searchResults) {
        this.setState({
            searchResults: searchResults,
            searchLoading: false
        });
    }
    handleSearchDataError(error) {
        this.setState({
            searchResults: null,
            searchLoading: false,
            errorLoadingSearchData: true
        });
    }
    handleKeyPress(event) {
        if (event.key === KEY_ENTER) {
            this.goToPage(1);
        }
    }
    convertValues = (dropDownValues, isDocumentTypeOrAnalyst) => {
        if (!dropDownValues || dropDownValues.length === 0) {
            return null;
        }
        let dropDownValuesArray = [];
        for (let i = 0; i < dropDownValues.length; i++) {
            if (isDocumentTypeOrAnalyst) {
                dropDownValuesArray.push({
                    code: dropDownValues[i].value,
                    firmName: dropDownValues[i].firmName,
                });
            } else {
                dropDownValuesArray.push(dropDownValues[i].value);
            }
        }
        return dropDownValuesArray;
    }
    handleFicmEqToggle(e) {
        switch(e.target.value) {
            case "strategy":
                this.setState({
                    firmName: FIRM_NAME_STRATEGY,
                    firmNameV2: FIRM_NAME_STRATEGY,
                    filteredDropDownData: SearchService.filterDropDownDataByFirmName(this.state.dropDownData, FIRM_NAME_STRATEGY),
                    team: TEAM.STR,
                    symbol: null,
                    industry: null,
                    analysts: null,
                    documentTypes: null,
                    products: null,
                    isFirstSearch : false
                }, this.getDropDown);
            break;
            case "ca":
                this.setState({
                    firmName: FIRM_NAME_COWEN,
                    firmNameV2: FIRM_NAME_CA,
                    filteredDropDownData: SearchService.filterDropDownDataByFirmName(this.state.dropDownData, FIRM_NAME_COWEN),
                    team: TEAM.CA,
                    symbol: null,
                    regions: null,
                    industry: null,
                    analysts: null,
                    documentTypes: null,
                    products: null,
                    isFirstSearch : false
                }, this.getDropDown);
            break;
            case "us":
                this.setState({
                    firmName: FIRM_NAME_COWEN,
                    firmNameV2: FIRM_NAME_US,
                    filteredDropDownData: SearchService.filterDropDownDataByFirmName(this.state.dropDownData, FIRM_NAME_COWEN),
                    team: TEAM.US,
                    symbol: null,
                    regions: null,
                    industry: null,
                    analysts: null,
                    documentTypes: null,
                    products: null,
                    isFirstSearch : false
                }, this.getDropDown);
            break;
            case "wrg":
                this.setState({
                    firmName: FIRM_NAME_COWEN,
                    firmNameV2: FIRM_NAME_WA,
                    filteredDropDownData: SearchService.filterDropDownDataByFirmName(this.state.dropDownData, FIRM_NAME_COWEN),
                    team: TEAM.WRG,
                    symbol: null,
                    regions: null,
                    industry: null,
                    analysts: null,
                    documentTypes: null,
                    products: null,
                    isFirstSearch : false
                }, this.getDropDown);
            break;
            default: throw new Error('Firm name not found' );
        }
    }

    getFirmsDetail(item, index) {
        if(this.hasAccessFicmEqToggle) {
            return (
                <div className="form-check form-check-inline" key={index}>
                    <input className="form-check-input"
                        type="radio"
                        name="radiogroup1"
                        disabled={this.state.searchLoading}
                        id={item?.id}
                        onChange={this.handleFicmEqToggle}
                        checked={this.state.team === item?.team}
                        value={item?.team} />
                    <label className="form-check-label"
                        htmlFor={item.id}>{item.label}</label>
                </div>
            )
        }
    }

    render() {
        return (
            <section className="tds-section-search">
                <div className="tds-container">
                    <h1>Search</h1>
                    {
                        this.state.dropDownLoading &&
                        <Spinner minHeight={500} />
                    }
                    {
                        !this.state.dropDownLoading &&
                        <React.Fragment>
                            {
                                this.hasAccessFicmEqToggle && !this.state.dropDownLoading &&
                                <div className="tds-search-row firm-name-filter">
                                    <fieldset>
                                    {
                                        SearchFirms.map((firm, index) => {
                                            if (UserService.isEQOnly() && firm.firmName !== FIRM_NAME_STRATEGY) {
                                                return this.getFirmsDetail(firm, index)
                                            } else if (UserService.isBoth()) {
                                                return this.getFirmsDetail(firm, index);
                                            } else {
                                                return "";
                                            }
                                        })
                                    }
                                    </fieldset>
                                </div>
                            }
                            <div className="tds-search-row first-row">
                                <div className="tds-search-item search-item-keywords form-group">
                                    <input
                                        className="tds-search-item-input form-control"
                                        name="keywords"
                                        type="text"
                                        value={this.state.keywords || ''}
                                        placeholder={this.keywordsPlaceholder}
                                        onChange={this.handleKeywordsChange}
                                        onKeyPress={this.handleKeyPress}
                                    />
                                    <span className="td-icon td-icon-search icon-small"></span>
                                </div>
                                <div className="tds-search-item search-item-symbol-look-up form-group">
                                    { 
                                        this.hasAccessSymbol && (this.state.firmName === FIRM_NAME_COWEN) &&
                                        <React.Fragment>
                                            {
                                                !this.state.dropDownLoading && !this.state.errorLoadingDropDownData && this.state.dropDownData &&
                                                <Select
                                                    key="symbol"
                                                    options={this.state.dropDownData.symbols}
                                                    isMulti={true}
                                                    value={this.state.symbol}
                                                    name="symbol"
                                                    onChange={this.handleSymbolChange}
                                                    styles={ReactSelectDesktopStyles}
                                                    placeholder='Symbol(s)'
                                                />
                                            }
                                          
                                            {
                                                this.state.errorLoadingDropDownData &&
                                                <Select styles={ReactSelectDesktopStyles} placeholder="Symbol(s)" isDisabled={true} />
                                            }
                                        </React.Fragment>
                                    }
                                    {(!this.hasAccessSymbol || this.state.firmName === FIRM_NAME_STRATEGY) &&
                                        <Select styles={ReactSelectDesktopStyles} placeholder="Symbol(s)" isDisabled={true} />
                                    }
                                </div>
                                <div className="tds-search-item search-item-filter-btn-ctn form-group">
                                    <button
                                        className="btn btn-block td-btn-secondary-clear"
                                        onClick={this.handleFilterButtonClick}
                                        title={this.state.showFilter ? "Hide Filters" : "Show Filters"}
                                    >
                                        <img className="tds-filter-icon"
                                            src="/assets/img/filter.svg"
                                            alt="filter" />
                                        <span className="tds-filter-text">Filter</span>
                                    </button>
                                </div>
                            </div>
                            {
                                this.state.showFilter &&
                                <div className="tds-search-row">
                                    <div className="tds-search-item search-item-date-range form-group">
                                        <div className="tds-search-date-from-ctn">
                                            <label className="tds-search-item-label" htmlFor="date-range">Date Range</label>
                                            <DatePicker
                                                ref={(c) => this.startDateCalendar = c}
                                                className="tds-search-item-input form-control text-right"
                                                selected={this.state.startDate}
                                                placeholderText='MM/DD/YYYY'
                                                onChange={this.handleStartDateChange}
                                                maxDate={this.state.maxStartDate}
                                            />
                                            <span className="td-icon td-icon-bookAppointment"
                                                onClick={this.handleStartDateCalenderIconClick}></span>
                                        </div>
                                        <div className="tds-search-item-date-range-divider">-</div>
                                        <div className="tds-search-date-to-ctn">
                                            <label className="tds-search-item-label" htmlFor="date-range-from">&nbsp;</label>
                                            <DatePicker
                                                ref={(c) => this.endDateCalendar = c}
                                                className="tds-search-item-input form-control text-right"
                                                selected={this.state.endDate}
                                                placeholderText='MM/DD/YYYY'
                                                onChange={this.handleEndDateChange}
                                                maxDate={this.maxDate}
                                                minDate={this.state.minEndDate}
                                            />
                                            <span className="td-icon td-icon-bookAppointment"
                                                onClick={this.handleEndDateCalenderIconClick}></span>
                                        </div>
                                    </div>
                                    <div className="tds-search-item search-item-analyst form-group">
                                        <label className="tds-search-item-label" htmlFor="analyst">Analyst</label>
                                        {
                                            !this.state.errorLoadingDropDownData && this.state.filteredDropDownData && this.state.filteredDropDownData.analysts &&
                                            <Select
                                                key="SearchAnalyst"
                                                options={this.state.filteredDropDownData.analysts}
                                                isMulti={true}
                                                value={this.state.analysts}
                                                name="SearchAnalyst"
                                                onChange={this.handleAnalystsChange}
                                                styles={ReactSelectDesktopStyles}
                                                placeholder='Select Analyst(s)'
                                            />
                                        }
                                        {
                                            this.state.errorLoadingDropDownData &&
                                            <Select styles={ReactSelectDesktopStyles} placeholder="Select Analyst(s)" isDisabled={true} />
                                        }
                                    </div>
                                    <div className="tds-search-item search-item-products form-group">
                                        <label className="tds-search-item-label" htmlFor="products">Products</label>
                                        {
                                            !this.state.errorLoadingDropDownData && (UserService.isFicmOnly() || this.state.firmName === FIRM_NAME_STRATEGY) &&
                                            <Select
                                                key="SearchProducts"
                                                options={this.state.dropDownData.products}
                                                isMulti={true}
                                                value={this.state.products}
                                                name="SearchProducts"
                                                onChange={this.handleProductChange}
                                                styles={ReactSelectDesktopStyles}
                                                placeholder='Select Product(s)'
                                            />
                                        }
                                        {( this.state.errorLoadingDropDownData || ( UserService.isEQ() && this.state.firmName === FIRM_NAME_COWEN )) &&
                                            <Select styles={ReactSelectDesktopStyles} placeholder="Select Product(s)" isDisabled={true} />
                                        }
                                    </div>
                                    <div className="tds-search-item search-item-industries form-group">
                                        <label className="tds-search-item-label" htmlFor="industries">Industries</label>
                                        {
                                            this.hasAccessSymbol && (this.state.firmName === FIRM_NAME_COWEN) &&
                                            <React.Fragment>
                                                {
                                                    !this.state.errorLoadingDropDownData &&
                                                    <Select
                                                        key="SearchIndustry"
                                                        options={this.state.dropDownData.industries}
                                                        isMulti={true}
                                                        value={this.state.industry}
                                                        name="SearchIndustry"
                                                        onChange={this.handleIndustryChange}
                                                        styles={ReactSelectDesktopStyles}
                                                        placeholder='Select Industries'
                                                    />
                                                }
                                               
                                            </React.Fragment>
                                        }
                                        {
                                            ((this.state.errorLoadingDropDownData || (!this.hasAccessSymbol || this.state.firmName === FIRM_NAME_STRATEGY))) &&
                                            <Select styles={ReactSelectDesktopStyles} placeholder="Select Industries" isDisabled={true} />
                                        }
                                    </div>
                                    <div className="tds-search-item search-item-regions form-group">
                                        <label className="tds-search-item-label" htmlFor="regions">Regions</label>
                                        {
                                            (UserService.isFicmOnly() || this.state.firmName === FIRM_NAME_STRATEGY) &&
                                            <React.Fragment>
                                                {
                                                    !this.state.errorLoadingDropDownData &&
                                                    <Select
                                                        key="SearchRegion"
                                                        options={this.state.dropDownData.regions}
                                                        isMulti={true}
                                                        value={this.state.regions}
                                                        name="SearchRegion"
                                                        onChange={this.handleRegionsChange}
                                                        styles={ReactSelectDesktopStyles}
                                                        placeholder='Select Region(s)'
                                                    />
                                                }
                                            </React.Fragment>
                                        }
                                        {
                                            (this.state.errorLoadingDropDownData || ( UserService.isEQ() && this.state.firmName === FIRM_NAME_COWEN )) &&
                                            <Select styles={ReactSelectDesktopStyles} placeholder="Select Region(s)" isDisabled={true} />
                                        }
                                    </div>
                                    <div className="tds-search-item search-item-document-type form-group">
                                        <label className="tds-search-item-label" htmlFor="document-type">Document Type</label>
                                        {
                                            !this.state.errorLoadingDropDownData && this.state.filteredDropDownData && this.state.filteredDropDownData.documentTypes &&
                                            <Select
                                                options={this.state.filteredDropDownData.documentTypes}
                                                isMulti={true}
                                                value={this.state.documentTypes}
                                                name="SearchDocumentType"
                                                onChange={this.handleDocumentTypesChange}
                                                styles={ReactSelectDesktopStyles}
                                                placeholder='Select Document Type(s)'
                                            />
                                        }
                                        {
                                            this.state.errorLoadingDropDownData &&
                                            <Select styles={ReactSelectDesktopStyles} placeholder="Select Document Type(s)" isDisabled={true} />
                                        }
                                    </div>
                                </div>
                            }
                            <div className="tds-search-row search-buttons">
                                <div className="tds-search-button">
                                    <button
                                        className="btn btn-block td-btn-primary-light"
                                        title="Search"
                                        disabled={this.state.searchLoading}
                                        onClick={this.goToPageOne}>Search</button>
                                </div>
                                <div className="tds-clear-button">
                                    <button className="btn btn-block td-btn-secondary-clear"
                                        title="Clear"
                                        onClick={this.handleCancel}>Clear</button>
                                </div>
                            </div>
                            {
                                this.state.searchLoading &&
                                <Spinner minHeight={500} />
                            }
                            {
                                !this.state.searchLoading &&
                                this.state.searchResults &&
                                this.state.searchResults.results &&
                                this.state.searchResults.results.length > 0 &&
                                <React.Fragment>
                                    <SearchResultsComponent
                                        results={this.state.searchResults.results}
                                        changeSort={this.changeSort}
                                        orderBy={this.state.orderBy}
                                        exactFirm = {this.state.firmNameV2}
                                    />
                                    <PaginationComponent
                                        currentPage={this.state.currentPage}
                                        resultsPerPage={SEARCH_RESULTS_PAGE_SIZE}
                                        total={this.state.searchResults.total}
                                        goToPage={this.goToPage}
                                    />
                                </React.Fragment>
                            }
                            {
                                !this.state.isNoSearchYet && !this.state.searchLoading && !this.state.errorLoadingSearchData &&
                                (!this.state.searchResults || !this.state.searchResults.results ||
                                    (this.state.searchResults.results &&
                                        this.state.searchResults.results.length === 0
                                    )
                                ) &&
                                <div className="tds-search-no-results">
                                    <ErrorComponent type={ERROR_TYPE.SEARCH_RESULTS} />
                                </div>
                            }
                            {
                                (!this.state.searchResults ||this.state.isFirstSearch) && !this.state.errorLoadingSearchData &&
                                <div className="tds-search-no-results"></div>
                            }
                            {
                                !this.state.searchLoading && this.state.errorLoadingSearchData &&
                                <div className="tds-search-no-results error">
                                    <ErrorComponent type={ERROR_TYPE.ERROR_ALL} />
                                </div>
                            }

                        </React.Fragment>
                    }
                </div>
            </section >
        )
    }
}

export { SearchComponent as UndecoratedSearchComponent }; //for testing purposes
export default withRouter(SearchComponent);