export const ASSET_SECURITIZATION_QUERY_PARAM_START_DATE = 'startDate';
export const ASSET_SECURITIZATION_QUERY_PARAM_END_DATE = 'endDate';
export const ASSET_SECURITIZATION_QUERY_PARAM_PAGE = 'page';
export const ASSET_SECURITIZATION_QUERY_PARAM_REGION = 'region';
export const ASSET_SECURITIZATION_QUERY_PARAM_DATE_FORMAT = 'MM/DD/YYYY';
export const ASSET_SECURITIZATION_PAGE_SIZE = 10;
export const INVESTMENT_GRADE_PAGE_SIZE = 10;
export const ASSET_SECURITIZATION_TYPE = {
    CANADA: 'canada',
    US: 'us',
    ALL: 'all'
};