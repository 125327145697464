import React from "react";
import { withRouter } from "react-router-dom";
import MainCardsComponent from "./main-cards/MainCards.component";
import SpotlightComponent from "./spotlight/Spotlight.component";
import TailoredForYouComponent from "./tailored-for-you/TailoredForYou.component";
import HomePageTabsComponent from "./home-page-tabs/HomePageTabs.component";
import ShowContentComponent from '../../components/misc/show-content/ShowContent.component';
import ErrorComponent from "../misc/error/Error.component";
import {
  EQUITY_OVERVIEW_PAGE_TITLE,
  HOME_PAGE_TITLE
} from '../../constants/PageTitle.constants';
import {
  AND,
  EQUALS,
  ERROR_TYPE, QUESTION_MARK, URL_QUERY_PARAMS_TAB
} from '../../constants/Misc.constants';
import SideBarComponent from "../side-bar/SideBar.component";
import Spinner from "../spinner/Spinner.component";
import HomePageService from "../../services/HomePage.service";
import UtilsService from "../../services/Utils.service";
import UserService from "../../services/User.service";
import queryString from 'query-string';
import { ROUTE_HOME, ROUTE_PATH_EQUITIES_OVERVIEW_PAGE } from "../../constants/RoutePaths.constants";
import {
  HOME_PAGE_MOST_POPULAR_TAB,
  HOME_PAGE_MOST_RECENT_TAB,
  HOME_PAGE_REAL_TIME_COVERAGE_TAB,
  HOME_PAGE_IN_DEPTHS_TAB,
  HOME_PAGE_MOST_RECENT_PAGE,
  HOME_PAGE_MOST_POPULAR_PAGE,
  HOME_PAGE_REAL_TIME_COVERAGE_PAGE,
  HOME_PAGE_IN_DEPTHS_PAGE
 } from "../../constants/HomePage.constants";
import { RTC_DOC_TYPE_URL_PARAM, RTC_REGION_URL_PARAM } from "../../constants/RealTimeCoverage.constants";

const updateObjectDefault = {tab: HOME_PAGE_MOST_POPULAR_TAB, mostRecentPage: 1, mostPopularPage:1, realTimeCoveragePage:1, inDepthsPage:1, rtcDocType: '', rtcRegion:'' };

class HomePageComponent extends React.Component {
  _mounted = false;
  constructor(props) {
    super(props);
    this.state = {
      homePageData: null,
      homePageDataLoading: true,
      homePageDataError: false,
      tailoredForYouData: null,
      tailoredForYouDataLoading: true,
      tailoredForYouDataError: false,
      tab: null,
      mostRecentPage: 1,
      mostPopularPage: 1,
      realTimeCoveragePage: 1,
      inDepthsPage: 1,
      urlParams: ''
    };
    let urlQueryParams = queryString.parse(this.props.location.search);
    if (urlQueryParams.mostRecentPage) {
      this.state.mostRecentPage = urlQueryParams.mostRecentPage;
    } 
    if (urlQueryParams.mostPopularPage) {
      this.state.mostPopularPage = urlQueryParams.mostPopularPage;
    }
    if (urlQueryParams.realTimeCoveragePage) {
      this.state.realTimeCoveragePage = urlQueryParams.realTimeCoveragePage;
    }
    if (urlQueryParams.inDepthsPage) {
      this.state.inDepthsPage = urlQueryParams.inDepthsPage;
    }
    if (urlQueryParams.rtcDocType) {
      this.state.rtcDocType = urlQueryParams.rtcDocType;
    }
    if (urlQueryParams.rtcRegion) {
      this.state.rtcRegion = UtilsService.getHomePageRegionByValue(urlQueryParams.rtcRegion);
    }
    this.state.tab = HOME_PAGE_MOST_POPULAR_TAB;
    if (UserService.isEQOnly() || this.props.eqOnly) {
      this.state.tab = HOME_PAGE_MOST_RECENT_TAB;
    }
    if (urlQueryParams.tab) {
      this.state.tab = urlQueryParams.tab;
    }
    this.getHomePageData = this.getHomePageData.bind(this);
    this.getHomePageDataThen = this.getHomePageDataThen.bind(this);
    this.getHomePageDataError = this.getHomePageDataError.bind(this);
    this.getTailoredForYouData = this.getTailoredForYouData.bind(this);
    this.getTailoredForYouDataThen = this.getTailoredForYouDataThen.bind(this);
    this.getTailoredForYouDataError = this.getTailoredForYouDataError.bind(this);
    this.pageChange = this.pageChange.bind(this);
    this.tabChange = this.tabChange.bind(this);
    this.updateUrl = this.updateUrl.bind(this);
    this.rtcDocTypeChange = this.rtcDocTypeChange.bind(this);
    this.rtcRegionChange = this.rtcRegionChange.bind(this);
  }

  componentWillUnmount() {
    this.unlisten();
  }

  changeStateForQueryString = (search) => {
    const urlQueryParams = queryString.parse(search);

    this.setState({mostRecentPage: urlQueryParams.mostRecentPage ? urlQueryParams.mostRecentPage : 1});
    this.setState({mostPopularPage: urlQueryParams.mostPopularPage ? urlQueryParams.mostPopularPage : 1})
    this.setState({realTimeCoveragePage: urlQueryParams.realTimeCoveragePage ? urlQueryParams.realTimeCoveragePage : 1})
    this.setState({inDepthsPage: urlQueryParams.inDepthsPage ? urlQueryParams.inDepthsPage : 1})

    this.setState({rtcDocType: urlQueryParams.rtcDocType ? urlQueryParams.rtcDocType : ''});
    this.setState({rtcRegion: urlQueryParams.rtcRegion ? UtilsService.getHomePageRegionByValue(urlQueryParams.rtcRegion) : ''});

    let tab = HOME_PAGE_MOST_POPULAR_TAB;
    if (UserService.isEQOnly() || this.props.eqOnly) {
      tab = HOME_PAGE_MOST_RECENT_TAB;
    }
    if (urlQueryParams.tab) {
      tab = urlQueryParams.tab;
    }
    this.setState({tab: tab});
    this.setState({urlParams: search});
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) =>{
      this.changeStateForQueryString(location.search);
    });

    if(this.props.eqOnly) {
      document.title = EQUITY_OVERVIEW_PAGE_TITLE;
    }
    else {
      document.title = HOME_PAGE_TITLE;
    }
    this._mounted = true;
    this.getHomePageData();
    this.getTailoredForYouData();
  }
  getHomePageData() {
    HomePageService.getHomePageData(this.props.eqOnly)
      .then(this.getHomePageDataThen)
      .catch(this.getHomePageDataError);
  }
  getHomePageDataThen(homePageData) {
    var _homePageData = homePageData;
    if (_homePageData.mainCards.middleCards) {
      _homePageData.mainCards.middleCards = UtilsService.spliceFirst(_homePageData.mainCards.middleCards, 2);
    }
    if (_homePageData.spotlightItems) {
      _homePageData.spotlightItems = UtilsService.spliceFirst(_homePageData.spotlightItems, 5);
    }
    this.setState({
      homePageData: _homePageData,
      homePageDataLoading: false,
    });
  }
  getHomePageDataError(error) {
    this.setState({
      homePageDataLoading: false,
      homePageDataError: true,
    });
  }
  getTailoredForYouData() {
    HomePageService.getTailoredForYouData(this.props.eqOnly)
      .then(this.getTailoredForYouDataThen)
      .catch(this.getTailoredForYouDataError);
  }
  getTailoredForYouDataThen(tailoredForYouData) {
    var _tailoredForYouData = tailoredForYouData;
    if (_tailoredForYouData.tailoredForYouItems) {
      _tailoredForYouData.tailoredForYouItems = UtilsService.spliceFirst(
        _tailoredForYouData.tailoredForYouItems, 6);
    }
    this.setState({
      tailoredForYouData: _tailoredForYouData,
      tailoredForYouDataLoading: false
    });
  }
  getTailoredForYouDataError(error) {
    this.setState({
      tailoredForYouDataLoading: false,
      tailoredForYouDataError: true,
    });
  }
  pageChange(tab, page){
      let tabs = [HOME_PAGE_MOST_POPULAR_TAB, HOME_PAGE_MOST_RECENT_TAB, HOME_PAGE_REAL_TIME_COVERAGE_TAB, HOME_PAGE_IN_DEPTHS_TAB]
      let urls = [HOME_PAGE_MOST_POPULAR_PAGE, HOME_PAGE_MOST_RECENT_PAGE, HOME_PAGE_REAL_TIME_COVERAGE_PAGE, HOME_PAGE_IN_DEPTHS_PAGE];
      const stateUpdate = {};
      let url = '';
      for (let i=0; i < tabs.length; i++) {
          if (tabs[i] === tab) {
              stateUpdate[urls[i]] = page;
              url = urls[i];
              break;
          }
      }
      if(this.state.tab !== tab || this.state[`${url}`] !== page) {
        this.updateUrl({...this.state, ...stateUpdate});
      }
  }

  tabChange(tab) {
    this.updateUrl({...updateObjectDefault, tab});
  }
  rtcDocTypeChange(value){
    this.updateUrl({...updateObjectDefault, tab: this.state.tab, rtcRegion: this.state.rtcRegion, rtcDocType: value});
  }
  rtcRegionChange(value){
    this.updateUrl({...updateObjectDefault, tab: this.state.tab, rtcDocType: this.state.rtcDocType, rtcRegion: value});
  }
  updateUrl(updateObj) {
    var url = this.props.eqOnly ? ROUTE_PATH_EQUITIES_OVERVIEW_PAGE : ROUTE_HOME;
    var urlParamsAdded = false;
    var defaultTab = HOME_PAGE_MOST_POPULAR_TAB;
    if (UserService.isEQOnly() || this.props.eqOnly) {
      defaultTab = HOME_PAGE_MOST_RECENT_TAB;
    }
    if (updateObj.tab && (updateObj.tab !== defaultTab))  {
      url += QUESTION_MARK + URL_QUERY_PARAMS_TAB + EQUALS + updateObj.tab;
      urlParamsAdded = true;
    }
    let pages = [updateObj.mostPopularPage, updateObj.mostRecentPage, updateObj.realTimeCoveragePage, updateObj.inDepthsPage];
    let urls = [HOME_PAGE_MOST_POPULAR_PAGE, HOME_PAGE_MOST_RECENT_PAGE, HOME_PAGE_REAL_TIME_COVERAGE_PAGE, HOME_PAGE_IN_DEPTHS_PAGE];
    for (let i = 0; i < 4; i++) {
      if (pages[i] > 1) {
        if (urlParamsAdded) {
          url += AND;
        } else {
          url += QUESTION_MARK;
        }
        url += urls[i] + EQUALS + pages[i]
        urlParamsAdded = true;
      }
    }
    if (updateObj.rtcDocType && (updateObj.rtcDocType !== ''))  {
      if (urlParamsAdded) {
        url += AND;
      } else {
        url += QUESTION_MARK;
      }
      url += RTC_DOC_TYPE_URL_PARAM + EQUALS + updateObj.rtcDocType;
      urlParamsAdded = true;
    }
    if (updateObj.rtcRegion && (updateObj.rtcRegion.value !== ''))  {
      if (urlParamsAdded) {
        url += AND;
      } else {
        url += QUESTION_MARK;
      }
      url += RTC_REGION_URL_PARAM + EQUALS + updateObj.rtcRegion.value;
      urlParamsAdded = true;
    }
    this.props.history.push(url);
  }
  render() {
    return (
      <div className="tds-homepage-container">
        <ShowContentComponent
          if={this.state.homePageDataLoading}
        >
          <div className="tds-three-column-title-container">
            <Spinner minHeight={100} />
          </div>
        </ShowContentComponent>
        <ShowContentComponent
          if={!this.state.homePageDataLoading && this.state.homePageDataError}
        >
          <div className="tds-three-column-title-container error-top-cards">
            <ErrorComponent type={ERROR_TYPE.ERROR_ALL} />
          </div>
        </ShowContentComponent>

        {!this.state.homePageDataLoading && !this.state.homePageDataError &&
          <React.Fragment>
            <MainCardsComponent
              mainCardData={this.state.homePageData.mainCards}
            />
            <SpotlightComponent
              spotlightData={this.state.homePageData.spotlightItems}
            />
            <div className="tds-divider-space-10">&nbsp;</div>
          </React.Fragment>
        }
        <div className="tds-three-column-container">
          <ShowContentComponent
            if={this.state.tailoredForYouDataLoading}
          >
            { (UserService.isFicm() && !this.props.eqOnly) &&
              <div className="tds-three-column-title-container">
                <h2>Tailored For You</h2>
              </div>
            }
            <Spinner marginTop={'20px'} noContainer={true} />
          </ShowContentComponent>
          <ShowContentComponent
            if={!this.state.tailoredForYouDataLoading && this.state.tailoredForYouDataError}
          >
            { (UserService.isFicm() && !this.props.eqOnly) &&
              <div className="tds-three-column-title-container">
                <h2>Tailored For You</h2>
              </div>
            }
            <ErrorComponent type={ERROR_TYPE.ERROR_ALL} />
          </ShowContentComponent>
          {(!this.state.tailoredForYouDataLoading && !this.state.tailoredForYouDataError) &&
            <React.Fragment>
              <TailoredForYouComponent
                eqOnly={this.props.eqOnly}
                tailoredForYouData={this.state.tailoredForYouData.tailoredForYouItems}
              />
            </React.Fragment>
          }
          <HomePageTabsComponent
              key={this.state.urlParams}
              eqOnly={this.props.eqOnly}
              initialMostRecentPage={this.state.mostRecentPage}
              initialMostPopularPage={this.state.mostPopularPage}
              initialRealTimeCoveragePage={this.state.realTimeCoveragePage}
              initialInDepthsPage={this.state.inDepthsPage}
              initialTab={this.state.tab}
              initialRtcDocType={this.state.rtcDocType}
              initialRtcRegion={this.state.rtcRegion}
              pageChange={this.pageChange}
              tabChange={this.tabChange}
              rtcDocTypeChange={this.rtcDocTypeChange}
              rtcRegionChange={this.rtcRegionChange}
          />
        </div>
        <SideBarComponent eqOnly={this.props.eqOnly} />
      </div>
    );
  }
}
export default withRouter(HomePageComponent);