export const COMPANY_DETAILS_ACTION_NOTES_PAGE_SIZE = 10;
export const COMPANY_DETAILS_TABS = {
    ACTION_NOTES: 'ACTION_NOTES',
    INDUSTRY_NOTES: 'INDUSTRY_RESEARCH_NOTES_BULLETINS',
    FLASH_NOTES: 'FLASH_NOTES_FAST_FACTS',
    STOCKS_STATISTICS_SCORES: 'STOCKS_STATISTICS_SCORES'
}

export const STOCKS_STATISTICS_FIELD_KEYS = {    
    TWELVE_MONTH_DIV: "TwelveMonthDiv",
    TWELVE_MONTH_TOTAL_RETURN: "TwelveMonthTotalReturn",
    BETA: "Beta",
    BV_SHARE: "BVShare",
    CASH_SHARE: "CashShare",
    ENTERPRISE_VALUE: "EnterpriseValue",
    FCFYIELD: "FCFYield",
    FLOAT_SHARES: "FloatShares",
    NAVPS: "NAVPS",
    NET_DEBT: "NetDebt",
    PRICING_DATE: "PricingDate",
    ROE: "ROE",
    ROIC: "ROIC",
    DILSHARESOUT: "DilSharesOut",
    SHORTINTEREST: "ShortInterest",
    WORKINGCAP: "WorkingCap",
    FLOATCAP: "FloatCap"
};

export const STOCKS_STATISTICS_FIELD_LABELS = {
TWELVE_MONTH_DIV: "12-Month Div (Est.)",
TWELVE_MONTH_TOTAL_RETURN: "12-Month Total Return",
BETA: "Beta",
BV_SHARE: "BV/Share",
CASH_SHARE: "Cash/Share",
ENTERPRISE_VALUE: "Enterprise Value (MM)",
FCF_YIELD: "FCF Yield",
FLOAT_SHARES: "Float Shares (mm)",
NAVPS: "NAVPS",
NET_DEBT: "Net Debt (MM)",
PRICING_DATE: "Pricing Date",
ROE: "ROE (LTM)",
ROIC: "ROIC",
DIL_SHARES_OUT: "Dil. Shares Out (MM)",
SHORT_INTEREST: "Short Interest",
WORKING_CAP: "Working Cap ($mm)",
FLOAT_CAP: "Float Cap ($mm)"
};

export const ESG_SCORES_FIELD_KEYS = {    
    ESG_SCORES: "EsgScores",
    ESG_INDUSTRY_PERCENTILE: "EsgIndustryPercentile",
    AIR_QUALITY: "AirQuality",
    BUSINESS_ETHICS: "BusinessEthics",
    BUSINESS_MODEL: "BusinessModel",
    COMPETITIVE: "Competitive",
};

export const ESG_SCORES_FIELD_LABELS = {
    ESG_SCORES: "ESG Scores",
    ESG_INDUSTRY_PERCENTILE: "ESG Industry Percentile (All Categories)",
    ESG_MATERIALITY_INSIGHT: "ESG Materiality Insight",
    INSIGHT_ACCESS_AFFORDABILITY: "Insight Access and Affordability",
    INSIGHT_AIR_QUALITY: "Insight Air Quality",
    INSIGHT_BUSINESS_ETHICS: "Insight Business Ethics",
    INSIGHT_BUSINESS_MODEL: "Insight Business Model",
    INSIGHT_CLIMATE_CHANGE: "Insight Climate Change",
    INSIGHT_COMPETITIVE: "Insight Competitive",
    INSIGHT_CRITICAL_RISK: "Insight Critical Risk",
    INSIGHT_CUSTOMER_PRIVACY: "Insight Customer Privacy",
    INSIGHT_CUSTOMER_WELFARE: "Insight Customer Welfare",
    INSIGHT_DATA_SECURITY: "Insight Data Security",
    INSIGHT_ECOLOGICAL: "Insight Ecological",
    INSIGHT_EMPLOYEE_ENGAGEMENT: "Insight Employee Engagement",
    INSIGHT_EMPLOYEE_HEALTH: "Insight Employee Health",
    INSIGHT_ENERGY: "Insight Energy",
    INSIGHT_GHG_EMISSIONS: "Insight GHG Emissions",
    INSIGHT_HAZARDOUS_MATERIALS: "Insight Hazardous Materials",
    INSIGHT_HUMAN_RIGHTS: "Insight Human Rights",
    INSIGHT_LABOR_PRACTICES: "Insight Labor Practices",
    INSIGHT_LEGAL_REGULATION: "Insight Legal Regulation",
    INSIGHT_MATERIAL_SOURCING: "Insight Material Sourcing",
    INSIGHT_PRODUCT_LIFECYCLE: "Insight Product Lifecycle",
    INSIGHT_PRODUCT_QUALITY: "Insight Product Quality",
    INSIGHT_SELLING_PRACTICES: "Insight Selling Practices",
    INSIGHT_SUPPLY_CHAIN: "Insight Supply Chain",
    INSIGHT_SYSTEMATIC_RISK: "Insight Systematic Risk",
    INSIGHT_WATER_WASTEWATER: "Insight Water Wastewater",
    ACCESS_AND_AFFORDABILITY: "Access and Affordability",
    AIR_QUALITY: "Air Quality",
    BUSINESS_ETHICS: "Business Ethics",
    BUSINESS_MODEL: "Business Model",
    CLIMATE_CHANGE: "Climate Change",
    COMPETITIVE: "Competitive",
    CRITICAL_RISK: "Critical Risk",
    CUSTOMER_PRIVACY: "Customer Privacy",
    CUSTOMER_WELFARE: "Customer Welfare",
    DATA_SECURITY: "Data Security",
    ECOLOGICAL: "Ecological",
    EMPLOYEE_ENGAGEMENT: "Employee Engagement",
    EMPLOYEE_HEALTH: "Employee Health",
    ENERGY: "Energy",
    GHG_EMISSIONS: "GHG Emissions",
    HUMAN_RIGHTS: "Human RIghts",
    LABOR_PRACTICES: "Labor Practices",
    LEGAL_REGULATION: "Legal Regulation",
    MATERIAL_SOURCING: "Material Sourcing",
    PRODUCT_LIFECYCLE: "Product Lifecycle",
    PRODUCT_QUALITY: "Product Quality",
    SELLING_PRACTICES: "Selling Practices",
    SUPPLY_CHAIN: "Supply Chain",
    SYSTEMATIC_RISK: "Systematic Risk",
    HAZARDOUS_MATERIALS: "Hazardous Materials",
    WATER_WASTEWATER: "Water Wastewater"
};

export const STOCKS_STATS_HEADER_LABEL = "Stocks & Statistics";
export const ESG_HEADER_LABEL = "ESG Scores";
export const TEASER_CHAR_LIMIT = 250;