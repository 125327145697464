import React, { useEffect, useRef } from "react";
import ModalComponent from "../misc/modal/Modal.component";
import { REQUEST_ACCESS_MAIL_FROM, REQUEST_ACCESS_MAIL_FROM_MAILTO } from "../../constants/LandingPage.constants";

export default function InvestmentGradeComingSoonPage() {
  const modalRef = useRef();
  useEffect(() => {
    modalRef.current.showModal();
  }, []);
  return (
    <div className="tds-igc-coming-soon-container">
      <div
        className="tds-container tds-access-denied"
      >
        <h1>Access Denied</h1>
        <p>
          To request access, please contact <a
            href={REQUEST_ACCESS_MAIL_FROM_MAILTO}
            title={REQUEST_ACCESS_MAIL_FROM}
          >
             {REQUEST_ACCESS_MAIL_FROM}
          </a>
        </p>
      </div>
      <ModalComponent
        id={"headerModal"}
        className={"tds-header-modal"}
        ref={modalRef}
      >
        <React.Fragment>
          <h1>Coming Soon</h1>
          <p>This feature is Coming Soon.</p>
          <p>
            Be sure to check back for comprehensive and detailed coverage from
            our top Analysts.
          </p>
        </React.Fragment>
      </ModalComponent>
    </div>
  );
}
