import React from 'react';
import HomePageTabItem from "./HomePageTabItem.component";
import {
    HOME_PAGE_MOST_RECENT_TAB,
    HOME_PAGE_MOST_POPULAR_TAB,
    HOME_PAGE_REAL_TIME_COVERAGE_TAB,
    HOME_PAGE_IN_DEPTHS_TAB
} from '../../../constants/HomePage.constants';
import {
    REAL_TIME_COVERAGE_HOME_TYPE
} from '../../../constants/RealTimeCoverage.constants';
import UserService from '../../../services/User.service';
import HomePageRealTimeCoverageComponent
    from "../../product-page/real-time-coverage/HomePageRealTimeCoverage.component";

export default class HomePageTabsComponent extends React.Component {
    containerClassName = 'tds-tab-menu-container';
    constructor(props) {
        super(props);
        this.state = {
            tabName: HOME_PAGE_MOST_POPULAR_TAB,
        };
        if (UserService.isEQOnly()) {
            this.state.tabName = HOME_PAGE_MOST_RECENT_TAB;
            this.containerClassName += ' two-tabs';
        }
        if (UserService.isBoth()) {
            if(this.props.eqOnly) {
                this.containerClassName += ' two-tabs';
            }
            else {
                this.containerClassName += ' four-tabs';
            }
        }
        if (this.props.initialTab) {
            this.state.tabName = this.props.initialTab;
        }
        this.onChangeValue = this.onChangeValue.bind(this);
        this.sendTabChangeParent = this.sendTabChangeParent.bind(this);
        this.pageChange = this.pageChange.bind(this);
        this.rtcDocTypeChange = this.rtcDocTypeChange.bind(this);
        this.rtcRegionChange = this.rtcRegionChange.bind(this);
    }
    onChangeValue(event) {
        this.setState({
            tabName: event.target.value
        }, this.sendTabChangeParent(event.target.value));
    }
    sendTabChangeParent(value) {
        if (this.props.tabChange) {
            this.props.tabChange(value)
        }
    }
    pageChange(tab, value) {
        if (this.props.pageChange) {
            this.props.pageChange(tab, value);
        }
    }
    rtcDocTypeChange(value) {
        if (this.props.rtcDocTypeChange) {
            this.props.rtcDocTypeChange(value)
        }
    }
    rtcRegionChange(value) {
        if (this.props.rtcRegionChange) {
            this.props.rtcRegionChange(value)
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className={this.containerClassName}>
                    <fieldset className="td-multi-single-select-buttons td-multi-single-select-buttons-segmented">
                        <div className="td-multi-single-select-buttons-group" >
                            {
                                ((UserService.isFicm() || UserService.isBoth()) && !this.props.eqOnly) &&
                                <div className="td-multi-single-select-button-wrapper">
                                    <input
                                        onChange={this.onChangeValue}
                                        type="radio"
                                        value={HOME_PAGE_MOST_POPULAR_TAB}
                                        name="selectButtonSegmented3Group"
                                        id="selectButtonSegmented3-1"
                                        checked={this.state.tabName === HOME_PAGE_MOST_POPULAR_TAB}
                                    />
                                    <label htmlFor="selectButtonSegmented3-1" title="Most Popular">Most Popular</label>
                                </div>
                            }
                            <div className="td-multi-single-select-button-wrapper">
                                <input
                                    onChange={this.onChangeValue}
                                    type="radio"
                                    value={HOME_PAGE_MOST_RECENT_TAB}
                                    name="selectButtonSegmented3Group"
                                    id="selectButtonSegmented3-2"
                                    checked={this.state.tabName === HOME_PAGE_MOST_RECENT_TAB}
                                />
                                <label htmlFor="selectButtonSegmented3-2" title="Most Recent">Most Recent</label>
                            </div>
                            {
                                ((UserService.isFicm() || UserService.isBoth()) && !this.props.eqOnly) &&
                                <div className="td-multi-single-select-button-wrapper">
                                    <input
                                        onChange={this.onChangeValue}
                                        type="radio"
                                        value={HOME_PAGE_REAL_TIME_COVERAGE_TAB}
                                        name="selectButtonSegmented3Group"
                                        id="selectButtonSegmented3-3"
                                        checked={this.state.tabName === HOME_PAGE_REAL_TIME_COVERAGE_TAB}
                                    />
                                    <label htmlFor="selectButtonSegmented3-3" title="Real-Time Coverage">Real-Time Coverage</label>
                                </div>
                            }
                            {
                                (UserService.isEQ() || UserService.isBoth()) &&
                                <div className="td-multi-single-select-button-wrapper">
                                    <input
                                        onChange={this.onChangeValue}
                                        type="radio"
                                        value={HOME_PAGE_IN_DEPTHS_TAB}
                                        name="selectButtonSegmented3Group"
                                        id="selectButtonSegmented3-4"
                                        checked={this.state.tabName === HOME_PAGE_IN_DEPTHS_TAB}
                                    />
                                    <label htmlFor="selectButtonSegmented3-4" title="Indepth Reports">
                                        { UserService.isBoth() && !this.props.eqOnly ? 'Indepth Reports - Equities' : 'Indepth Reports'}
                                    </label>
                                </div>
                            }
                        </div>
                    </fieldset>
                </div>
                {
                    ((UserService.isFicm() || UserService.isBoth()) && !this.props.eqOnly) && this.state.tabName === HOME_PAGE_MOST_POPULAR_TAB &&
                    <HomePageTabItem
                        eqOnly={this.props.eqOnly}
                        display={this.state.tabName === HOME_PAGE_MOST_POPULAR_TAB}
                        type={HOME_PAGE_MOST_POPULAR_TAB}
                        pageChange={this.pageChange}
                        initialPage={this.props.initialMostPopularPage}
                    />
                }
                {this.state.tabName === HOME_PAGE_MOST_RECENT_TAB &&
                    <HomePageTabItem
                        eqOnly={this.props.eqOnly}
                        display={this.state.tabName === HOME_PAGE_MOST_RECENT_TAB}
                        type={HOME_PAGE_MOST_RECENT_TAB}
                        pageChange={this.pageChange}
                        initialPage={this.props.initialMostRecentPage}
                    />
                }
                {
                    ((UserService.isFicm() || UserService.isBoth()) && !this.props.eqOnly) &&
                    <div className="tds-tab-container home-page" style={
                        {
                            'display': this.state.tabName === HOME_PAGE_REAL_TIME_COVERAGE_TAB ? 'block' : 'none'
                        }
                    }>
                        {this.state.tabName === HOME_PAGE_REAL_TIME_COVERAGE_TAB && <HomePageRealTimeCoverageComponent
                            //key={this.props.initialRtcDocType + this.props.initialRtcRegion}
                            type={REAL_TIME_COVERAGE_HOME_TYPE}
                            pageChange={this.pageChange}
                            initialPage={this.props.initialRealTimeCoveragePage}
                            docTypeChange={this.rtcDocTypeChange} 
                            regionChange={this.rtcRegionChange}
                            initialDocType={this.props.initialRtcDocType}
                            initialRegion={this.props.initialRtcRegion}
                        />}
                    </div>
                }
                {
                    (UserService.isEQ() || UserService.isBoth()) && this.state.tabName === HOME_PAGE_IN_DEPTHS_TAB &&
                    <HomePageTabItem
                        eqOnly={this.props.eqOnly}
                        display={this.state.tabName === HOME_PAGE_IN_DEPTHS_TAB}
                        type={HOME_PAGE_IN_DEPTHS_TAB}
                        pageChange={this.pageChange}
                        initialPage={this.props.initialInDepthsPage}
                    />
                }
            </React.Fragment>
        )
    }
}